import { BankAccountWithNominalAccount } from "../bank-information";
import styles from "./connected-bank-account-cards.module.scss";
import { Card } from "primereact/card";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  bankNumber: "BANKNUMMER",
  bank: "Bank",
  iban: "IBAN",
  noBankAccounts: "Keine Bankkonten verbunden.",
  connectBankAccount: "Verbinden Sie ein Bankkonto aus den folgenden Optionen.",
};

const ENGLISH_TRANSLATIONS = {
  bankNumber: "BANK NUMBER",
  bank: "Bank",
  iban: "IBAN",
  noBankAccounts: "No bank accounts connected.",
  connectBankAccount: "Connect a bank account from the options below.",
};

export function ConnectedBankAccountCards(props: {
  bankAccountsWithNominalAccounts: BankAccountWithNominalAccount[];
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const bankAccountsWithNominalAccounts = props.bankAccountsWithNominalAccounts;

  return (
    <div className={styles["cardsGrid"]}>
      {bankAccountsWithNominalAccounts.length > 0 ? (
        bankAccountsWithNominalAccounts.map((account) => (
          <Card key={account.id} className={styles.connectedBankAccountCard}>
            <div className={styles.bankNumber}>
              {translations.bankNumber}
              <b>{account.nominal_account.account_number}</b>
            </div>
            <div>
              <div className={styles.detail}>
                {translations.bank}
                <b>{account.nominal_account.account_name}</b>
              </div>
              <div className={styles.detail}>
                {translations.iban}
                <b>{account.iban}</b>
              </div>
            </div>
          </Card>
        ))
      ) : (
        <div className={styles.gridFullWidth}>
          <div className={styles.emptyMessage}>
            <b>{translations.noBankAccounts}</b>
            <span className={styles.bigText}>
              {translations.connectBankAccount}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
