import { useCallback, useContext, useMemo } from "react";
import { ResultsContext } from "./results-context";
import styles from "./results.module.scss";
import { ReactDataGrid } from "../../../../react-data-grid";
import { Column } from "react-data-grid";
import { Document } from "./results-context";
import { throwError } from "../../../../throw-error";
import { Tooltip } from "primereact/tooltip";
import { Badge } from "primereact/badge";
import { SortColumn, SortingContext } from "../sorting/sorting-context";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { formatFileSize } from "../../../../file-size";

const GERMAN_TRANSLATIONS = {
  documentName: "Dokumentname",
  uploadedDate: "Hochgeladenes Datum",
  type: "Typ",
  mobileUpload: "Mobil hochladen",
  size: "Größe",
  issues: "Probleme",
  loadingMore: "Weitere Zeilen werden geladen...",
  reviewNeededIssueType: "Überprüfung erforderlich",
  invalidIbansIssueType: "Ungültige IBANs",
};

const ENGLISH_TRANSLATIONS = {
  documentName: "Document Name",
  uploadedDate: "Uploaded Date",
  type: "Type",
  mobileUpload: "Mobile Upload",
  size: "Size",
  issues: "Issues",
  loadingMore: "Loading more rows...",
  reviewNeededIssueType: "Review needed",
  invalidIbansIssueType: "Invalid IBANs",
};

// Intl formatter for dates
const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

export function Results() {
  const { results, setSelectedDocumentId, setOffset } =
    useContext(ResultsContext) || throwError();

  const sorting = useContext(SortingContext) || throwError();
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const getRowKey = useCallback(
    (r: Partial<Document>) => r.id || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn(sortColumn as SortColumn | undefined);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return sorting.sortColumn ? [sorting.sortColumn] : [];
  }, [sorting]);

  const columns: Column<Document>[] = useMemo(() => {
    const _columns: Column<Document>[] = [
      {
        key: "name",
        name: translations.documentName,
        resizable: true,
        renderCell: (args) => {
          if (!args.row.name) {
            return <></>;
          }
          return args.row.name;
        },
      },
      {
        key: "created_at",
        name: translations.uploadedDate,
        resizable: true,
        sortable: true,
        renderCell: (args) => {
          const value = args.row.createdAt;
          return dateFormatter.format(value);
        },
      },
      {
        key: "file_type",
        name: translations.type,
        width: 150,
        resizable: true,
        renderCell: (args) => {
          if (args.row.document_upload.type === "mobile") {
            return translations.mobileUpload;
          } else {
            return args.row.document_upload.file_type;
          }
        },
      },
      {
        key: "size",
        name: translations.size,
        width: 150,
        resizable: true,
        renderCell: (args) => {
          if (args.row.document_upload.type === "mobile") {
            return <></>;
          } else {
            return formatFileSize(args.row.document_upload.file_size);
          }
        },
      },
      {
        key: "issues",
        name: translations.issues,
        frozen: true,
        width: 70,
        cellClass: (row) => {
          const uniqueClass = `issues-cell-${row.id}`;
          return `${uniqueClass} ${styles.issuesCell}`;
        },
        renderCell: (args) => {
          const uniqueClass = `issues-cell-${args.row.id}`;

          const issues = args.row.issues ?? [];

          if (issues.length === 0) {
            return <></>;
          }

          return (
            <>
              <Tooltip position={"left"} target={`.${uniqueClass}`}>
                <ul className="list-disc list-inside">
                  {issues.map((i) => {
                    let description;

                    if (i.type === "review_needed") {
                      description = translations.reviewNeededIssueType;
                    } else if (i.type === "invalid_ibans") {
                      description = translations.invalidIbansIssueType;
                    } else {
                      throw new Error();
                    }

                    return (
                      <li key={i.id}>
                        <p className={styles.issueDescription}>{description}</p>
                      </li>
                    );
                  })}
                </ul>
              </Tooltip>
              <Badge value={issues.length} severity={"danger"} />
            </>
          );
        },
      },
    ];

    return _columns;
  }, [translations]);

  const data = results.data;

  if (!data && results.loading) {
    return <ProgressSpinner />;
  }

  if (!data) {
    return <></>;
  }

  return (
    <>
      <ReactDataGrid
        className={`${styles.table} rdg-light`}
        rows={results.data.rows}
        columns={columns}
        rowKeyGetter={getRowKey}
        onSelectedCellChange={(args) => {
          if (!args.row) {
            return;
          }
          setSelectedDocumentId(args.row.id);
        }}
        onWheelAndBottom={() => {
          setOffset(data.rows.length);
        }}
        sortColumns={sortColumns}
        onSortColumnsChange={onSortColumnsChange}
      />

      {results.loading && (
        <div className={styles.loadingMore}>{translations.loadingMore}</div>
      )}
    </>
  );
}
