import { ReactNode } from "react";
import styles from "./personal-accounts.module.scss";
import { PersonalAccountsToolbar } from "./toolbar/personal-accounts-toolbar";
import { PersonalAccounts as _PersonalAccounts } from "./results/personal-accounts-results";
import { DetailsMask } from "./details-mask/personal-accounts-details-mask";
import { PersonalAccountsFiltersContextProvider } from "./toolbar/personal-accounts-filters-context";
import { PersonalAccountsSortingContextProvider } from "./sorting/personal-accounts-sorting-context";
import { PersonalAccountsResultsContextProvider } from "./results/personal-accounts-results-context";

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export default function PersonalAccounts() {
  return (
    <PersonalAccountsFiltersContextProvider>
      <PersonalAccountsSortingContextProvider>
        <PersonalAccountsResultsContextProvider>
          <Page>
            <PersonalAccountsToolbar />
            <_PersonalAccounts />
            <DetailsMask />
          </Page>
        </PersonalAccountsResultsContextProvider>
      </PersonalAccountsSortingContextProvider>
    </PersonalAccountsFiltersContextProvider>
  );
}
