import { ReactNode } from "react";
import styles from "./nominal-accounts.module.scss";
import { NominalAccountsToolbar } from "./toolbar/nominal-accounts-toolbar";
import { NominalAccounts as _NominalAccounts } from "./results/nominal-accounts-results";
import { DetailsMask } from "./details-mask/nominal-accounts-details-mask";
import { NominalAccountsFiltersContextProvider } from "./toolbar/nominal-accounts-filters-context";
import { NominalAccountsSortingContextProvider } from "./sorting/nominal-accounts-sorting-context";
import { NominalAccountsResultsContextProvider } from "./results/nominal-accounts-results-context";

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export default function NominalAccounts() {
  return (
    <NominalAccountsFiltersContextProvider>
      <NominalAccountsSortingContextProvider>
        <NominalAccountsResultsContextProvider>
          <Page>
            <NominalAccountsToolbar />
            <_NominalAccounts />
            <DetailsMask />
          </Page>
        </NominalAccountsResultsContextProvider>
      </NominalAccountsSortingContextProvider>
    </NominalAccountsFiltersContextProvider>
  );
}
