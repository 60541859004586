import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { CLIENT_LOGIN_ROUTE } from "../../../login/routes";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { HEADINGS_CLASS_NAMES } from "../../../../../ui/headings";

const GERMAN_TRANSLATIONS = {
  accountCreated: "Konto erstellt.",
  loginInfo:
    "Sie können sich jetzt mit Ihrer E-Mail und dem neu festgelegten Passwort anmelden.",
  login: "Anmelden",
};

const ENGLISH_TRANSLATIONS = {
  accountCreated: "Account created.",
  loginInfo: "You can now login with your email and newly set password.",
  login: "Login",
};

export function AcceptInviteEnd() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.textAlignCenter}>
      <h1
        className={`${HEADINGS_CLASS_NAMES.h1} ${styles.marginBottomSpacer3}`}
      >
        {translations.accountCreated}
      </h1>
      <p className={styles.marginBottomSpacer2}>{translations.loginInfo}</p>
      <Link
        to={CLIENT_LOGIN_ROUTE.getHref()}
        className={"p-button p-button-primary"}
      >
        {translations.login}
      </Link>
    </div>
  );
}
