import { ReactNode, createContext, useMemo } from "react";
import {
  AccountingClient,
  FormOptions,
} from "./invited-accounting-client-loader";

function useProvider(props: {
  accountingClient: AccountingClient;
  setAccountingClient: (accountingClient: AccountingClient) => void;
  formOptions: FormOptions;
}) {
  return useMemo(() => {
    return {
      accountingClient: props.accountingClient,
      setAccountingClient: props.setAccountingClient,
      formOptions: props.formOptions,
    };
  }, [props.accountingClient, props.setAccountingClient, props.formOptions]);
}

export const AccountingClientContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function AccountingClientContextProvider(props: {
  accountingClient: AccountingClient;
  setAccountingClient: (accountingClient: AccountingClient) => void;
  formOptions: FormOptions;
  children: ReactNode;
}) {
  const value = useProvider({
    accountingClient: props.accountingClient,
    setAccountingClient: props.setAccountingClient,
    formOptions: props.formOptions,
  });

  return (
    <AccountingClientContext.Provider value={value}>
      {props.children}
    </AccountingClientContext.Provider>
  );
}
