import { useState } from "react";
import styles from "./invited-accounting-clients.module.scss";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import OnboardingHeader from "../accounting-client-id/create/onboarding-header";
import { InvitedAccountingClientsTable } from "./invited-accounting-clients/table/invited-accounting-clients-table";
import { InvitedAccountingClientDetailsPanel } from "./invited-accounting-clients/details/invited-accounting-client-details-panel";
import { InvitedAccountingClient } from "./invited-accounting-clients/loader/invited-accounting-clients-loader";
import { CREATE_ACCOUNTING_CLIENT_ROUTE } from "../accounting-client-id/create/create-accounting-client-routes";

const ENGLISH_TRANSLATIONS = {
  newClientInitialization: "Client Initialization",
  initializeNewClient: "Initialize New Client",
  newClient: "New Client",
};

const GERMAN_TRANSLATIONS = {
  newClientInitialization: "Mandanten initialisieren",
  initializeNewClient: "Neuen Mandanten initialisieren",
  newClient: "Neuer Mandant",
};

export function InvitedAccountingClients(props: {
  accountingClients: InvitedAccountingClient[];
  onAccountingClientCreated: (
    accountingClient: InvitedAccountingClient
  ) => void;
  onAccountingClientUpdated: (
    accountingClient: InvitedAccountingClient
  ) => void;
  toast: React.RefObject<Toast>;
}) {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const navigate = useNavigate();

  const [selectedAccountingClientId, setSelectedAccountingClientId] = useState<
    string | undefined
  >(undefined);

  const [isCreatingNewAccountingClient, setIsCreatingNewAccountingClient] =
    useState(false);

  const selectedAccountingClient = props.accountingClients.find(
    (accountingClient) => accountingClient.id === selectedAccountingClientId
  );

  return (
    <div className={styles.container}>
      <OnboardingHeader
        titleProps={{
          text: translations.newClientInitialization,
        }}
        previousButtonProps={{
          disabled: true,
          onClick: () => {},
        }}
        nextButtonProps={{
          onClick: () => {
            if (selectedAccountingClient) {
              navigate(
                CREATE_ACCOUNTING_CLIENT_ROUTE.getHref(
                  selectedAccountingClient.id
                )
              );
            }
          },
          disabled: !selectedAccountingClient?.permission_granted,
        }}
      />
      <div className={styles.body}>
        <InvitedAccountingClientsTable
          accountingClients={props.accountingClients}
          selectedAccountingClient={selectedAccountingClient}
          onAccountingClientSelect={(accountingClientId) => {
            setSelectedAccountingClientId(accountingClientId);
            setIsCreatingNewAccountingClient(false);
          }}
          onAccountingClientUpdated={props.onAccountingClientUpdated}
          toastRef={props.toast}
        />
        <Button
          icon="pi pi-user-plus"
          label={translations.initializeNewClient}
          onClick={() => {
            setSelectedAccountingClientId(undefined);
            setIsCreatingNewAccountingClient(true);
          }}
          className={styles.button}
        />
      </div>
      {(() => {
        if (isCreatingNewAccountingClient) {
          return (
            <div className={styles.detailsPanel}>
              <InvitedAccountingClientDetailsPanel
                selectedAccountingClient={undefined}
                onCancel={() => {
                  setSelectedAccountingClientId(undefined);
                  setIsCreatingNewAccountingClient(false);
                }}
                onAccountingClientCreated={(accountingClient) => {
                  setSelectedAccountingClientId(accountingClient.id);
                  setIsCreatingNewAccountingClient(false);
                  props.onAccountingClientCreated(accountingClient);
                }}
                onAccountingClientUpdated={props.onAccountingClientUpdated}
              />
            </div>
          );
        } else if (selectedAccountingClient) {
          return (
            <div className={styles.detailsPanel}>
              <InvitedAccountingClientDetailsPanel
                selectedAccountingClient={selectedAccountingClient}
                onCancel={() => {
                  setSelectedAccountingClientId(undefined);
                  setIsCreatingNewAccountingClient(false);
                }}
                onAccountingClientCreated={(accountingClient) => {
                  setSelectedAccountingClientId(accountingClient.id);
                  setIsCreatingNewAccountingClient(false);
                  props.onAccountingClientCreated(accountingClient);
                }}
                onAccountingClientUpdated={props.onAccountingClientUpdated}
              />
            </div>
          );
        } else {
          return <></>;
        }
      })()}
    </div>
  );
}
