import { useState, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../../../communication-errors/communication-error-messages";
import { throwError } from "../../../../../../../../throw-error";
import { useMainApi } from "../../../../../../../../main-api";
import z from "zod";
import styles from "./send-email-to-accounting-client.module.scss";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { useAccountingClientId } from "../../../../accounting-client-id";
import { AccountingClientContext } from "../../../accounting-client-context";

const GERMAN_TRANSLATIONS = {
  error: "Fehler",
  failedToSendEmail:
    "E-Mail-Adresse nicht gefunden. Bitte prüfen Sie die Kontaktdaten des Kunden.",
  emailInstruction:
    "Senden Sie eine E-Mail an den Mandanten, um ihn durch den Prozess der Verbindung seiner Bankkonten mit unserer Plattform zu führen.",
  sendEmailButton: "E-Mail senden",
  needToSendNewEmail: "Müssen Sie eine neue E-Mail senden?",
};

const ENGLISH_TRANSLATIONS = {
  error: "Error",
  failedToSendEmail:
    "Email address not found. Please check client's contact details.",
  emailInstruction:
    "Send an email to the client to guide them through the process of connecting their bank accounts to our platform.",
  sendEmailButton: "Send email",
  needToSendNewEmail: "Need to send a new email?",
};

export function SendEmailToAccountingClient() {
  const { accountingClient, setAccountingClient } =
    useContext(AccountingClientContext) || throwError();
  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastRef = useRef<Toast>(null);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const sendEmail = async () => {
    setIsSubmitting(true);

    const response = await mainApi.fetchJSON({
      path: `/accounting_clients/${accountingClientId}/bank_accounts/trigger_new_bank_account_with_plaid_link_email`,
      method: "POST",
      schema: z.union([
        z.object({
          status: z.literal(404),
        }),
        z.object({
          status: z.literal(200),
          body: z.object({}),
        }),
      ]),
    });

    setIsSubmitting(false);

    if (response.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(response.error),
        detail: getCommunicationErrorMessage(response.error),
      });
      return;
    }

    if (response.response.status === 404) {
      toastRef.current?.show({
        severity: "error",
        summary: translations.error,
        detail: translations.failedToSendEmail,
      });

      return;
    }

    setAccountingClient({
      ...accountingClient,
      last_bank_connection_email_sent_at: new Date(),
    });
  };

  return (
    <div className={styles.emailFlowContainer}>
      <Toast ref={toastRef} />
      <span>{translations.emailInstruction}</span>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={sendEmail}
          label={translations.sendEmailButton}
          disabled={
            isSubmitting ||
            !!accountingClient.last_bank_connection_email_sent_at
          }
          loading={isSubmitting}
        />
        {accountingClient.last_bank_connection_email_sent_at && (
          <div className={styles.needToSendNewEmail}>
            <Button
              onClick={sendEmail}
              label={translations.needToSendNewEmail}
              link
              loading={isSubmitting}
            />
          </div>
        )}
      </div>
    </div>
  );
}
