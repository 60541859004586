import React, { useContext, useState, useCallback } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { throwError } from "../../../../../../../../../../throw-error";
import styles from "./personal-accounts-toolbar.module.scss";
import { PersonalAccountsFiltersContext } from "./personal-accounts-filters-context";
import { useCurrentLanguage } from "../../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  search: "Suche",
};

const ENGLISH_TRANSLATIONS = {
  search: "Search",
};

export function PersonalAccountsToolbar() {
  const { filters, setFilters } =
    useContext(PersonalAccountsFiltersContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [searchInput, setSearchInput] = useState(filters.search || "");

  const handleSearch = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchInput,
    }));
  }, [searchInput, setFilters]);

  const endContent = (
    <React.Fragment>
      <InputText
        placeholder={translations.search}
        className={styles.quarterSpacerMarginRight}
        value={searchInput}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchInput(e.target.value)
        }
        onKeyDown={(e) => e.key === "Enter" && handleSearch()}
      />
      <Button icon="pi pi-search" onClick={handleSearch} />
    </React.Fragment>
  );

  return <Toolbar end={endContent} />;
}
