import { TabView, TabPanel } from "primereact/tabview";
import PaymentAccounts from "./payment-accounts/payment-accounts";
import NominalAccounts from "./nominal-accounts/nominal-accounts";
import PersonalAccounts from "./personal-accounts/personal-accounts";
import styles from "./accounts.module.scss";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  nominalAccounts: "Sachkonten",
  personalAccounts: "Personenkonten",
  paymentAccounts: "Zahlungskonten",
};

const ENGLISH_TRANSLATIONS = {
  nominalAccounts: "Nominal Accounts",
  personalAccounts: "Personal Accounts",
  paymentAccounts: "Payment Accounts",
};

export function Accounts({
  activeIndex,
  setActiveIndex,
}: {
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const handleTabChange = (e: { index: number }) => {
    setActiveIndex(e.index);
  };

  return (
    <>
      <TabView
        className={styles.tabView}
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
      >
        <TabPanel
          style={{
            height: "100%",
          }}
          header={translations.nominalAccounts}
        >
          <NominalAccounts />
        </TabPanel>
        <TabPanel
          style={{
            height: "100%",
          }}
          header={translations.paymentAccounts}
        >
          <PaymentAccounts />
        </TabPanel>
        <TabPanel
          style={{
            height: "100%",
          }}
          header={translations.personalAccounts}
        >
          <PersonalAccounts />
        </TabPanel>
      </TabView>
    </>
  );
}
