import z from "zod";
import { useState, useRef, useEffect, useContext } from "react";
import { CommunicationError } from "../../../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../../../main-api";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import { ConnectedBankAccountCards } from "./connected-bank-account-cards/connected-bank-account-cards";
import { SendEmailToAccountingClient } from "./send-email-to-accounting-client/send-email-to-accounting-client";
import { ConnectBankAccountsToNominalAccounts } from "./connect-bank-accounts-to-nominal-accounts/connect-bank-accounts-to-nominal-accounts";
import styles from "./bank-information.module.scss";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import OnboardingHeader from "../../onboarding-header";
import { throwError } from "../../../../../../../throw-error";
import { AccountingClientContext } from "../../accounting-client-context";
import { HEADINGS_CLASS_NAMES } from "../../../../../../../ui/headings";

const GERMAN_TRANSLATIONS = {
  error: "Fehler",
  failedToFetchBankInformation: "Fehler beim Laden der Bankinformationen",
  loadingError: "Beim Laden der Bankinformationen ist ein Fehler aufgetreten.",
  connectedBankAccounts: "Verbundene Bankkonten",
  newBankAccountWithFinAPI: "Neues Bankkonto mit FinAPI",
  assignNominalAccount: "Sachkonto dem Bankkonto zuweisen",
  limitedNominalAccountsMessage:
    "Begrenzte Sachkonten zur Auswahl. Bitte sehen Sie sich weitere Konten an",
  uploadGDPDU: "Laden Sie die GDPDU-Datei hoch.",
  onboardingSuccessMessageTitle:
    "Email erfolgreich gesendet! Jetzt warten wir.",
  onboardingSuccessMessageDetail: (lastBankConnectionEmailSentAt: string) =>
    `Eine E-Mail wurde am ${lastBankConnectionEmailSentAt} an den Mandanten gesendet. Sie werden benachrichtigt, sobald der Mandant sein Bankkonto verbunden hat.`,
};

const ENGLISH_TRANSLATIONS = {
  error: "Error",
  failedToFetchBankInformation: "Failed to fetch bank information",
  loadingError: "There was an error loading the bank information.",
  connectedBankAccounts: "Connected bank accounts",
  newBankAccountWithFinAPI: "New bank account with FinAPI",
  assignNominalAccount: "Assign nominal account to the bank account",
  limitedNominalAccountsMessage:
    "Limited nominal accounts to choose from. To see more accounts, please",
  uploadGDPDU: "upload GDPDU File.",
  onboardingSuccessMessageTitle: "Email sent successfully! Now we wait.",
  onboardingSuccessMessageDetail: (lastBankConnectionEmailSentAt: string) =>
    `An email has been sent to the client at ${lastBankConnectionEmailSentAt}. You will be notified once the client has connected their bank account.`,
};

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
  timeStyle: "short",
});

export const NominalAccountSchema = z.object({
  id: z.string().uuid(),
  account_number: z.number(),
  account_name: z.string(),
});

export type NominalAccountToConnect = z.infer<typeof NominalAccountSchema>;

const BankAccountWithNominalAccountSchema = z.object({
  id: z.string().uuid(),
  iban: z.string(),
  nominal_account: NominalAccountSchema,
});

export type BankAccountWithNominalAccount = z.infer<
  typeof BankAccountWithNominalAccountSchema
>;

const BankAccountWithoutNominalSchema = z.object({
  id: z.string().uuid(),
  iban: z.string(),
});

export type BankAccountWithoutNominalAccount = z.infer<
  typeof BankAccountWithoutNominalSchema
>;

export const AccountingClientBankAccountsSchema = z.object({
  bank_accounts_with_nominal_accounts: z.array(
    BankAccountWithNominalAccountSchema
  ),
  bank_accounts_without_nominal_accounts: z.array(
    BankAccountWithoutNominalSchema
  ),
  nominal_accounts_to_connect: z.array(NominalAccountSchema),
});

export type AccountingClientBankAccounts = z.infer<
  typeof AccountingClientBankAccountsSchema
>;

export default function BankInformation(props: {
  onPrevStep: () => void;
  onNextStep: () => void;
}) {
  const mainApi = useMainApi();
  const toastRef = useRef<Toast>(null);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { accountingClient } =
    useContext(AccountingClientContext) || throwError();

  const [
    accountingClientBankInformationState,
    setAccountingClientBankInformationState,
  ] = useState<{
    loading?: boolean;
    data?: AccountingClientBankAccounts | null;
    error?: CommunicationError | undefined;
  }>({});

  useEffect(() => {
    const fetchAccountingClientBankAccounts = async () => {
      setAccountingClientBankInformationState({ loading: true });
      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_clients/${accountingClient.id}/bank_accounts`,
        schema: z.object({
          status: z.literal(200),
          body: AccountingClientBankAccountsSchema,
        }),
      });

      if (res.error) {
        setAccountingClientBankInformationState({ error: res.error });
        toastRef.current?.show({
          severity: "error",
          summary: translations.error,
          detail: translations.failedToFetchBankInformation,
        });
        return;
      }

      setAccountingClientBankInformationState({ data: res.response.body });
    };

    fetchAccountingClientBankAccounts();
  }, [
    mainApi,
    accountingClient.id,
    translations.error,
    translations.failedToFetchBankInformation,
  ]);

  return (
    <div className={styles.page}>
      <Toast ref={toastRef} />
      {(() => {
        if (accountingClientBankInformationState.loading) {
          return (
            <div className={styles.loadingContainer}>
              <ProgressSpinner />
            </div>
          );
        }

        if (accountingClientBankInformationState.error) {
          return (
            <div className={styles.errorContainer}>
              <Message severity="error" text={translations.loadingError} />
            </div>
          );
        }

        if (accountingClientBankInformationState.data) {
          return (
            <>
              <OnboardingHeader
                titleProps={{
                  text: translations.connectedBankAccounts,
                  className: styles.maxWidth,
                }}
                previousButtonProps={{
                  onClick: () => {
                    props.onPrevStep();
                  },
                }}
                nextButtonProps={{
                  onClick: () => {
                    props.onNextStep();
                  },
                }}
              />

              <div className={styles.maxWidth}>
                <div className={styles.marginBottom3}>
                  <div className={styles.cardsContainer}>
                    <ConnectedBankAccountCards
                      bankAccountsWithNominalAccounts={
                        accountingClientBankInformationState.data
                          .bank_accounts_with_nominal_accounts
                      }
                    />
                  </div>
                </div>
                <div className={styles.marginBottom3}>
                  <h3
                    className={`${styles.marginBottom2} ${HEADINGS_CLASS_NAMES.h3}`}
                  >
                    {translations.newBankAccountWithFinAPI}
                  </h3>
                  <SendEmailToAccountingClient />
                  {accountingClientBankInformationState.data
                    .nominal_accounts_to_connect.length !== 0 &&
                    accountingClient.last_bank_connection_email_sent_at && (
                      <div
                        className={`${styles.marginBottom3} ${styles.maxWidth}`}
                      >
                        <Message
                          severity="success"
                          className={styles.message}
                          content={
                            <>
                              <b>
                                {translations.onboardingSuccessMessageTitle}
                              </b>
                              <br />
                              {translations.onboardingSuccessMessageDetail(
                                dateFormatter.format(
                                  accountingClient.last_bank_connection_email_sent_at
                                )
                              )}
                            </>
                          }
                        />
                      </div>
                    )}
                </div>

                {accountingClientBankInformationState.data
                  ?.bank_accounts_without_nominal_accounts.length > 0 && (
                  <div className={`${styles.marginBottom3}`}>
                    <h3
                      className={`${styles.marginBottom2} ${HEADINGS_CLASS_NAMES.h3}`}
                    >
                      {translations.assignNominalAccount}
                    </h3>
                    <ConnectBankAccountsToNominalAccounts
                      results={
                        accountingClientBankInformationState.data || {
                          bank_accounts_with_nominal_accounts: [],
                          bank_accounts_without_nominal_accounts: [],
                          nominal_accounts_to_connect: [],
                        }
                      }
                      setResults={(newResults) =>
                        setAccountingClientBankInformationState(
                          (prevState) => ({
                            ...prevState,
                            data: newResults,
                          })
                        )
                      }
                      toastRef={toastRef}
                    />
                  </div>
                )}
              </div>
            </>
          );
        }

        return null;
      })()}
    </div>
  );
}
