import { useContext } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { EditBookingDetailsFormValue } from "../../details-panel/details-panel";
import styles from "./details.module.scss";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { Detail } from "./detail/detail-column";
import { Booking } from "../../results/results-context";
import { BookingsPageResourcesContext } from "../../page-resources";
import { throwError } from "../../../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

// Intl formatter that formats dates as '11th January 2023'
const dateFormatter = new Intl.DateTimeFormat(undefined, {
  day: "numeric",
  month: "short",
  year: "numeric",
});

const GERMAN_TRANSLATIONS = {
  transactionDate: "Transaktionsdatum",
  applicantName: "Name",
  amount: "Betrag",
  creditDebit: "Soll / Haben",
  taxCode: "Steuercode",
  taxPercentage: "Steuer %",
  account: "Konto",
  counterAccount: "Gegenkonto",
  description: "Beschreibung",
  status: "Status",
  debit: "Soll",
  credit: "Haben",
  actionNeeded: "Handlungsbedarf",
  correctedByAccountant: "Manuell korrigiert",
  processed: "Vollautomatisiert",
  historical: "Historisch",
};

const ENGLISH_TRANSLATIONS = {
  transactionDate: "Transaction Date",
  applicantName: "Applicant Name",
  amount: "Amount",
  creditDebit: "Credit / Debit",
  taxCode: "Tax Code",
  taxPercentage: "Tax %",
  account: "Account",
  counterAccount: "Counter Account",
  description: "Description",
  status: "Status",
  debit: "Debit",
  credit: "Credit",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  processed: "Processed",
  historical: "Historical",
};

export function Details(props: {
  selectedBooking: Booking;
  form: UseFormReturn<EditBookingDetailsFormValue>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { counter_accounts, tax_codes } =
    useContext(BookingsPageResourcesContext) || throwError();

  return (
    <div className={styles.rows}>
      <div className={styles.row}>
        <div className={styles.column}>
          <Detail label={translations.applicantName}>
            <p className={styles.bigText}>
              {props.selectedBooking.applicant_name}
            </p>
          </Detail>
        </div>{" "}
        <Divider layout="vertical" />
        <div className={styles.column}>
          <Detail label={translations.transactionDate}>
            <p className={styles.bigText}>
              {dateFormatter.format(props.selectedBooking.transaction_date)}
            </p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={styles.column}>
          <Detail label={translations.amount}>
            <p className={styles.bigText}>
              {props.selectedBooking.amount.toLocaleString()} €
            </p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={styles.column}>
          <Detail label={translations.creditDebit}>
            <p
              className={`${styles.bigText} ${props.selectedBooking.is_debit ? styles.debit : styles.credit}`}
            >
              {props.selectedBooking.is_debit
                ? translations.debit
                : translations.credit}
            </p>
          </Detail>
        </div>
      </div>
      <div className={styles.row}>
        <div className={`${styles.column} ${styles.minWidth0}`}>
          <Detail label={translations.counterAccount}>
            <Controller
              name="counter_account_id"
              control={props.form.control}
              render={({ field }) => {
                return (
                  <>
                    <Dropdown
                      id={field.name}
                      className={styles.width100}
                      options={counter_accounts.map((t) => ({
                        value: t.id,
                        label: `${t.number}: ${t.name}`,
                      }))}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      disabled={props.selectedBooking.status === "HISTORICAL"}
                    />
                  </>
                );
              }}
            />
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={`${styles.column} ${styles.minWidth0}`}>
          <Detail label={translations.taxCode}>
            <Controller
              name="tax_code_id"
              control={props.form.control}
              render={({ field }) => {
                return (
                  <>
                    <Dropdown
                      id={field.name}
                      className={styles.width100}
                      options={tax_codes.map((t) => ({
                        value: t.id,
                        label: `${t.tax_code} | ${t.description}`,
                      }))}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      disabled={props.selectedBooking.status === "HISTORICAL"}
                      showClear={true}
                    />
                  </>
                );
              }}
            />
          </Detail>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.columnExpand}>
          <Detail label={translations.description}>
            <p>{props.selectedBooking.booking_text}</p>
          </Detail>
        </div>
        <Divider layout="vertical" />

        <div className={styles.column}>
          <Detail label={translations.status}>
            <Tag
              className={`${
                props.selectedBooking.status === "HISTORICAL"
                  ? styles.historicalStatusTag
                  : ""
              }`}
              severity={(() => {
                if (props.selectedBooking.status === "ACTION_NEEDED") {
                  return "danger";
                } else if (
                  props.selectedBooking.status === "CORRECTED_BY_ACCOUNTANT"
                ) {
                  return "info";
                } else if (
                  props.selectedBooking.status === "PREDICTED_WITHOUT_ISSUES"
                ) {
                  return "success";
                } else if (props.selectedBooking.status === "HISTORICAL") {
                  return "success";
                } else {
                  throw new Error();
                }
              })()}
              value={(() => {
                if (props.selectedBooking.status === "ACTION_NEEDED") {
                  return translations.actionNeeded;
                } else if (
                  props.selectedBooking.status === "CORRECTED_BY_ACCOUNTANT"
                ) {
                  return translations.correctedByAccountant;
                } else if (
                  props.selectedBooking.status === "PREDICTED_WITHOUT_ISSUES"
                ) {
                  return translations.processed;
                } else if (props.selectedBooking.status === "HISTORICAL") {
                  return translations.historical;
                } else {
                  throw new Error();
                }
              })()}
            />
          </Detail>
        </div>
      </div>
    </div>
  );
}
