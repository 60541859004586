import { TabView, TabPanel } from "primereact/tabview";
import PaymentAccounts from "./payment-accounts/page";
import NominalAccounts from "./nominal-accounts/page";
import PersonalAccounts from "./personal-accounts/page";
import styles from "./accounts.module.scss";
import { AccountingClient } from "../../accountingClientLoader";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  nominalAccounts: "Sachkonten",
  personalAccounts: "Personenkonten",
  paymentAccounts: "Zahlungskonten",
};

const ENGLISH_TRANSLATIONS = {
  nominalAccounts: "Nominal Accounts",
  personalAccounts: "Personal Accounts",
  paymentAccounts: "Payment Accounts",
};

export function Accounts({
  accountingClient,
  activeIndex,
  setActiveIndex,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const handleTabChange = (e: { index: number }) => {
    setActiveIndex(e.index);
  };

  return (
    <>
      <TabView
        className={styles.tabView}
        activeIndex={activeIndex}
        onTabChange={handleTabChange}
      >
        <TabPanel
          style={{
            height: "100%",
          }}
          header={translations.nominalAccounts}
        >
          <NominalAccounts
            accountingClient={accountingClient}
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
          />
        </TabPanel>
        <TabPanel
          style={{
            height: "100%",
          }}
          header={translations.paymentAccounts}
        >
          <PaymentAccounts
            accountingClient={accountingClient}
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
          />
        </TabPanel>
        <TabPanel
          style={{
            height: "100%",
          }}
          header={translations.personalAccounts}
        >
          <PersonalAccounts
            accountingClient={accountingClient}
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
          />
        </TabPanel>
      </TabView>
    </>
  );
}
