import { useContext, useCallback, useMemo, useRef } from "react";
import { Column } from "react-data-grid";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import { DEFAULT_SORT_COLUMN } from "../sorting/personal-accounts-sorting-constants";
import styles from "./personal-accounts-results.module.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { getCommunicationErrorMessage } from "../../../../../../../../../../communication-errors/communication-error-messages";
import { throwError } from "../../../../../../../../../../throw-error";
import {
  SortColumn,
  PersonalAccountsSortingContext,
} from "../sorting/personal-accounts-sorting-context";
import {
  PersonalAccount,
  PersonalAccountsResultsContext,
} from "./personal-accounts-results-context";
import { Toast } from "primereact/toast";
import { useCurrentLanguage } from "../../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../../language/supported-languages";
import { ReactDataGrid } from "../../../../../../../../../../react-data-grid";

const GERMAN_TRANSLATIONS = {
  accountNumber: "Kontonummer",
  accountName: "Kontoname",
  debitorOrCreditor: "D/K",
  debitorLabel: "D",
  creditorLabel: "K",
  bookings: "Buchungen",
  loadingMoreRows: "Weitere Zeilen werden geladen...",
};

const ENGLISH_TRANSLATIONS = {
  accountNumber: "Account Number",
  accountName: "Account Name",
  bankAccount: "Bank Account",
  debitorOrCreditor: "D/C",
  debitorLabel: "D",
  creditorLabel: "C",
  bookings: "Bookings",
  loadingMoreRows: "Loading more rows...",
};

export function PersonalAccounts() {
  const { results, setOffset, setSelectedAccountId } =
    useContext(PersonalAccountsResultsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const sorting = useContext(PersonalAccountsSortingContext) || throwError();

  const toastRef = useRef<Toast>(null);

  const getRowKey = useCallback(
    (r: Partial<PersonalAccount>) => r.id || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn((sortColumn ?? DEFAULT_SORT_COLUMN) as SortColumn);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return [sorting.sortColumn];
  }, [sorting]);

  const columns: Column<PersonalAccount>[] = useMemo(() => {
    return [
      {
        key: "account_number",
        name: `${translations.accountNumber}`,
        editable: true,
        sortable: true,
      },
      {
        key: "account_name",
        name: `${translations.accountName}`,
        sortable: true,
        renderCell: (args) => {
          if (!args.row.account_name) {
            return <></>;
          }
          return <span>{args.row.account_name}</span>;
        },
      },
      {
        key: "debitor_or_creditor",
        name: `${translations.debitorOrCreditor}`,
        sortable: true,
        renderCell: (args) => {
          if (!args.row.debitor_or_creditor) {
            return <></>;
          }
          return (
            <span>
              {args.row.debitor_or_creditor === "creditor"
                ? `${translations.creditorLabel}`
                : `${translations.debitorLabel}`}
            </span>
          );
        },
      },
    ];
  }, [translations]);

  return (
    <>
      <Toast ref={toastRef} />
      {(() => {
        const data = results.data;

        if (results.loading && !results.data) {
          return (
            <div className={styles.loadingContainer}>
              <ProgressSpinner />
            </div>
          );
        }
        if (results.error) {
          return (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>
                {getCommunicationErrorMessage(results.error)}
              </p>
            </div>
          );
        }

        if (data && data.rows.length > 0) {
          return (
            <>
              <ReactDataGrid
                columns={columns}
                rows={results.data.rows}
                sortColumns={sortColumns}
                rowKeyGetter={getRowKey}
                onSortColumnsChange={onSortColumnsChange}
                onSelectedCellChange={(args) => {
                  if (!args.row) {
                    return;
                  }

                  setSelectedAccountId(args.row.id);
                }}
                onWheelAndBottom={() => {
                  setOffset(data.rows.length);
                }}
                className={`${styles.table}`}
              />

              {results.loading && (
                <div className={styles.loadingNextPageContainer}>
                  {translations.loadingMoreRows}
                </div>
              )}
            </>
          );
        }
      })()}
    </>
  );
}
