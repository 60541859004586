import { useContext } from "react";
import { Button } from "primereact/button";
import { throwError } from "../../../../../../../../throw-error";
import { QuickFiltersContext } from "./quick-filters-context";
import { FiltersContext } from "../filters/filters-context";

export function ResetFiltersButton() {
  const { setQuickFilters } = useContext(QuickFiltersContext) || throwError();
  const { setFilters } = useContext(FiltersContext) || throwError();
  return (
    <>
      <Button
        outlined={true}
        severity={"danger"}
        icon="pi pi-filter-slash"
        onClick={() => {
          setQuickFilters({
            time_frame_from: null,
            time_frame_to: null,
            search: null,
          });
          setFilters({
            amount_min: null,
            amount_max: null,
            booking_type: [],
            counter_account: null,
            status: [],
          });
        }}
      />
    </>
  );
}
