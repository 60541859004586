import { Button } from "primereact/button";
import styles from "./actions.module.scss";
import { useContext } from "react";
import { throwError } from "../../../../../../../../../../../throw-error";
import {
  PaymentAccount,
  PaymentAccountsResultsContext,
} from "../../results/payment-accounts-results-context";
import { useCurrentLanguage } from "../../../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  cancel: "Abbrechen",
  apply: "Anwenden",
};

const ENGLISH_TRANSLATIONS = {
  cancel: "Cancel",
  apply: "Apply",
};

export function Actions({
  isSubmitting,
}: {
  selectedAccount: PaymentAccount;
  isSubmitting: boolean;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { setSelectedAccountId } =
    useContext(PaymentAccountsResultsContext) || throwError();

  return (
    <div className={styles.actionBar}>
      <Button
        icon="pi pi-times"
        severity="danger"
        label={translations.cancel}
        type="button"
        onClick={() => setSelectedAccountId(undefined)}
      />
      <Button
        icon="pi pi-check"
        severity="success"
        label={translations.apply}
        className={styles.actionButton}
        loading={isSubmitting}
        type="submit"
      />
    </div>
  );
}
