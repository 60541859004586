import { useCallback, useContext, useMemo } from "react";
import { Column, SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import "primeicons/primeicons.css";
import styles from "./exports.module.scss";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { throwError } from "../../../../../../throw-error";
import { SortColumn, SortingContext } from "../../sorting/sorting-context";
import { ResultsContext, Export } from "../result-context";
import { ExportDetails } from "./export-details/export-details";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { ReactDataGrid } from "../../../../../../react-data-grid";
import { HEADINGS_CLASS_NAMES } from "../../../../../../ui/headings";

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
});

const GERMAN_TRANSLATIONS = {
  pastExports: "Vergangene Exporte",
  exportedDate: "Exportdatum",
  client: "Mandant",
  account: "Konto",
  no: "Nr.",
  summary: "Zusammenfassung",
  close: "Schließen",
};

const ENGLISH_TRANSLATIONS = {
  pastExports: "Past Exports",
  exportedDate: "Exported Date",
  client: "Client",
  account: "Account",
  no: "No",
  summary: "Summary",
  close: "Close",
};

export function Exports() {
  const { results, setSelectedExport, selectedExport } =
    useContext(ResultsContext) || throwError();
  const sorting = useContext(SortingContext) || throwError();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const getRowKey = useCallback(
    (r: Partial<Export>) => r.id || throwError(),
    []
  );

  const columns: Column<Export>[] = useMemo(() => {
    return [
      {
        key: "no",
        name: translations.no,
        width: 90,
        renderCell: (args) => {
          return args.rowIdx + 1;
        },
      },

      {
        key: "exported_at",
        name: translations.exportedDate,
        sortable: true,
        resizable: true,
        renderCell: (args) => {
          const value = args.row.exported_date;
          return dateFormatter.format(value);
        },
      },
      {
        key: "client",
        name: translations.client,
        renderCell: (args) => {
          if (!args.row.accounting_client) {
            return <></>;
          }
          return args.row.accounting_client.name;
        },
      },
      {
        key: "account",
        name: translations.account,
        renderCell: (args) => {
          if (!args.row.account) {
            return <></>;
          }
          return `${args.row.account.number} | ${args.row.account.name}`;
        },
      },
    ];
  }, [translations]);

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn(sortColumn as SortColumn | undefined);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return sorting.sortColumn ? [sorting.sortColumn] : [];
  }, [sorting]);

  const data = results.data;

  if (!data) {
    return <></>;
  }
  return (
    <div>
      <h3 className={`${styles.title} ${HEADINGS_CLASS_NAMES.h3}`}>
        {translations.pastExports}
      </h3>
      <ReactDataGrid
        className={`${styles.table}`}
        rows={data.rows}
        columns={columns}
        rowKeyGetter={getRowKey}
        sortColumns={sortColumns}
        onSortColumnsChange={onSortColumnsChange}
        onSelectedCellChange={(args) => {
          if (!args.row) {
            return;
          }
          setSelectedExport(args.row);
        }}
      />

      {selectedExport && (
        <Dialog
          className={styles.filtersOverlayPanel}
          visible
          draggable={false}
          onHide={() => {
            setSelectedExport(undefined);
          }}
          header={translations.summary}
          footer={() => (
            <div>
              <Button
                label={translations.close}
                onClick={() => setSelectedExport(undefined)}
              />
            </div>
          )}
        >
          <ExportDetails />
        </Dialog>
      )}
    </div>
  );
}
