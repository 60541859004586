import { ReactNode } from "react";
import styles from "./payment-accounts.module.scss";
import { PaymentAccountsToolbar } from "./toolbar/payment-accounts-toolbar";
import { PaymentAccounts as _PaymentAccounts } from "./results/payment-accounts-results";
import { DetailsMask } from "./details-mask/payment-accounts-details-mask";
import { PaymentAccountsFiltersContextProvider } from "./toolbar/payment-accounts-filters-context";
import { PaymentAccountsSortingContextProvider } from "./sorting/payment-accounts-sorting-context";
import { PaymentAccountsResultsContextProvider } from "./results/payment-accounts-results-context";

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export default function PaymentAccounts() {
  return (
    <PaymentAccountsFiltersContextProvider>
      <PaymentAccountsSortingContextProvider>
        <PaymentAccountsResultsContextProvider>
          <Page>
            <PaymentAccountsToolbar />
            <_PaymentAccounts />
            <DetailsMask />
          </Page>
        </PaymentAccountsResultsContextProvider>
      </PaymentAccountsSortingContextProvider>
    </PaymentAccountsFiltersContextProvider>
  );
}
