import { ReactNode, createContext, useMemo, useState } from "react";

export type QuickFilters = {
  time_frame_from: Date | null;
  time_frame_to: Date | null;
  search: string | null;
};

function useProvider() {
  const [quickFilters, setQuickFilters] = useState<QuickFilters>({
    time_frame_from: null,
    time_frame_to: null,
    search: null,
  });

  return useMemo(() => {
    return {
      quickFilters,
      setQuickFilters,
    };
  }, [quickFilters]);
}

export const QuickFiltersContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function QuickFiltersContextProvider(props: { children: ReactNode }) {
  const value = useProvider();

  return (
    <QuickFiltersContext.Provider value={value}>
      {props.children}
    </QuickFiltersContext.Provider>
  );
}
