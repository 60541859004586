import { ReactNode } from "react";
import styles from "./page.module.scss";
import { PersonalAccountsToolbar } from "./toolbar/personal-accounts-toolbar";
import { PersonalAccounts as _PersonalAccounts } from "./results/results";
import { DetailsMask } from "./details-mask/details-mask";
import { PersonalAccountsFiltersContextProvider } from "./toolbar/filters-context";
import { PersonalAccountsSortingContextProvider } from "./sorting/sorting-context";
import { PersonalAccountsResultsContextProvider } from "./results/results-context";
import { AccountingClient } from "../../../accountingClientLoader";

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export default function PersonalAccounts({
  accountingClient,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  return (
    <PersonalAccountsFiltersContextProvider accountingClient={accountingClient}>
      <PersonalAccountsSortingContextProvider>
        <PersonalAccountsResultsContextProvider
          selectedAccountId={selectedAccountId}
          setSelectedAccountId={setSelectedAccountId}
        >
          <Page>
            <PersonalAccountsToolbar />
            <_PersonalAccounts setSelectedAccountId={setSelectedAccountId} />
            <DetailsMask />
          </Page>
        </PersonalAccountsResultsContextProvider>
      </PersonalAccountsSortingContextProvider>
    </PersonalAccountsFiltersContextProvider>
  );
}
