import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { HEADINGS_CLASS_NAMES } from "../../../../ui/headings";
import { Navbar } from "./navbar/navbar";
import styles from "./top-section.module.scss";

const GERMAN_TRANSLATIONS = {
  documents: "Dokumente",
};

const ENGLISH_TRANSLATIONS = {
  documents: "Documents",
};

export function TopSection() {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <div className={styles.page}>
        <h2 className={`${styles.title} ${HEADINGS_CLASS_NAMES.h2}`}>
          {translations.documents}
        </h2>
        <Navbar />
      </div>
    </>
  );
}
