import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useId,
  useState,
} from "react";
import {
  FinApiBankConnection,
  FinApiBankConnectionSchema,
  FinApiState,
} from "../finapi";
import { Toast } from "primereact/toast";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { useMainApi } from "../../../../../main-api";
import { z } from "zod";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../communication-errors/communication-error-messages";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { throwError } from "../../../../../throw-error";
import { CommunicationError } from "../../../../../communication-errors/communication-errors";

const GERMAN_TRANSLATIONS = {
  requestCompleted: "Anfrage abgeschlossen, klicken Sie hier, um fortzufahren",
  bankAlreadyConnectedSummary: "Bank ist bereits verbunden",
  doNotCloseTabWarning: "Schließen Sie diese Registerkarte nicht",
  accountsConnected: "Konten erfolgreich verbunden",
};

const ENGLISH_TRANSLATIONS = {
  requestCompleted: "Request completed, click here to continue",
  doNotCloseTabWarning: "Do not close this tab while connecting your bank.",
  accountsConnected: "Accounts successfully connected",
};

export function UpdateFinApiPolling(props: {
  taskId: string;
  toastRef: React.MutableRefObject<Toast | null>;
  onError: (error: CommunicationError) => void;
  onSuccess: (bankConnection: FinApiBankConnection) => void;
}) {
  const id = useId();
  const mainApi = useMainApi();
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const res = await mainApi.fetchJSON({
          path: `/bank-accounts/finapi/update-bank-connection/${props.taskId}`,
          method: "GET",
          schema: z.union([
            z.object({
              status: z.literal(200),
              body: z.object({
                status: z.literal("COMPLETED"),
                bank_connection: FinApiBankConnectionSchema,
              }),
            }),
            z.object({
              status: z.literal(200),
              body: z.object({
                status: z.literal("IN_PROGRESS"),
              }),
            }),
          ]),
        });

        if (res.error) {
          props.onError(res.error);
          return;
        }

        const responseBody = res.response.body;

        if (responseBody.status === "COMPLETED") {
          props.onSuccess(responseBody.bank_connection);

          return;
        }

        await new Promise((resolve) => setTimeout(resolve, 3000));
      }
    })();
  }, [mainApi, props]);

  return (
    <div id={id}>
      <style>
        {`
        [id="${id}"] .loading {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 1rem;
        }
      `}
      </style>
      <div className="loading">
        <ProgressSpinner />
        <b>{translations.doNotCloseTabWarning}</b>
      </div>
    </div>
  );
}

export function UpdateFinApiItem({
  bankConnection,
  toastRef,
  setFinApiState,
  onClose,
}: {
  bankConnection: FinApiBankConnection;
  toastRef: React.MutableRefObject<Toast | null>;
  onClose: () => void;
  setFinApiState: Dispatch<SetStateAction<FinApiState>>;
}) {
  const dialogId = useId();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const [taskId, setTaskId] = useState<string>();

  const [state, setState] = useState<{
    loading?: boolean;
    data?: {
      taskId?: string;
      webformUrl?: string;
    };
  }>({});

  useEffect(() => {
    (async () => {
      setState({ loading: true });

      const res = await mainApi.fetchJSON({
        method: "PUT",
        path: `/bank-accounts/finapi/${bankConnection.id}/update-bank-connection`,
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            web_form_url: z.string(),
            finapi_task_id: z.string(),
          }),
        }),
      });

      setState({ loading: false });

      if (res.error) {
        toastRef.current?.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        return;
      }

      setState({
        data: {
          taskId: res.response.body.finapi_task_id,
          webformUrl: res.response.body.web_form_url,
        },
      });
    })();
  }, [mainApi, toastRef, bankConnection.id]);

  const onSuccess = useCallback(
    (bankConnection: FinApiBankConnection) => {
      setTaskId(undefined);

      setState({});

      setFinApiState((prevState) => {
        if (!prevState.data) {
          throw new Error();
        }

        return {
          ...prevState,
          data: prevState.data.map((b) =>
            b.id === bankConnection.id ? bankConnection : b
          ),
        };
      });

      onClose();

      toastRef.current?.show({
        severity: "success",
        summary: translations.accountsConnected,
      });
    },
    [onClose, setFinApiState, toastRef, translations]
  );

  const onError = useCallback(
    (error: CommunicationError) => {
      setTaskId(undefined);

      setState({});

      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(error),
        detail: getCommunicationErrorMessage(error),
      });

      onClose();
    },
    [onClose, toastRef]
  );

  return (
    <Dialog
      id={dialogId}
      modal={true}
      visible={true}
      onHide={() => {
        onClose();
      }}
    >
      <style>
        {`
        [id="${dialogId}"] {
          width: 50%
        }
        [id="${dialogId}"] .loading {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        [id="${dialogId}"] .main {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }`}
      </style>
      {(() => {
        if (taskId) {
          return (
            <UpdateFinApiPolling
              taskId={taskId}
              toastRef={toastRef}
              onSuccess={onSuccess}
              onError={onError}
            />
          );
        }

        if (state.loading) {
          return (
            <div className="loading">
              <ProgressSpinner />
            </div>
          );
        }

        if (state.data) {
          return (
            <div className="main">
              <a
                className={"p-button"}
                onClick={() => {
                  setTaskId(state.data?.taskId || throwError());
                }}
                href={state.data.webformUrl}
                target="_blank"
                rel="noreferrer"
              >
                <span className={"pi pi-arrow-up-right"}></span>

                {translations.requestCompleted}
              </a>
            </div>
          );
        }
      })()}
    </Dialog>
  );
}
