import { Button } from "primereact/button";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import styles from "./onboarding-header.module.scss";
import { HEADINGS_CLASS_NAMES } from "../../../../../ui/headings";

const GERMAN_TRANSLATIONS = {
  cancel: "Abbrechen",
  previous: "Zurück",
  next: "Weiter",
  finish: "Fertig",
};

const ENGLISH_TRANSLATIONS = {
  cancel: "Cancel",
  previous: "Previous",
  next: "Next",
  finish: "Finish",
};

type TitleProps = {
  text: string;
  className?: string;
};

type ButtonProps = {
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
};

const OnboardingHeader = ({
  titleProps,
  previousButtonProps,
  nextButtonProps,
  finishButtonProps,
}: {
  titleProps?: TitleProps;
  previousButtonProps?: ButtonProps;
  nextButtonProps?: ButtonProps;
  finishButtonProps?: ButtonProps;
}) => {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.container}>
      {titleProps && (
        <h2 className={`${titleProps?.className} ${HEADINGS_CLASS_NAMES.h2}`}>
          {titleProps.text}
        </h2>
      )}
      <div className={styles.buttonsGroup}>
        {previousButtonProps && (
          <Button
            type="button"
            label={translations.previous}
            onClick={previousButtonProps.onClick}
            loading={previousButtonProps.loading}
            disabled={previousButtonProps.disabled}
            className={styles.button}
            severity="secondary"
          />
        )}
        {nextButtonProps && (
          <Button
            label={translations.next}
            onClick={nextButtonProps.onClick}
            loading={nextButtonProps.loading}
            disabled={nextButtonProps.disabled}
            className={styles.button}
          />
        )}

        {finishButtonProps && (
          <Button
            type="button"
            severity={"success"}
            label={translations.finish}
            onClick={finishButtonProps.onClick}
            loading={finishButtonProps.loading}
            disabled={finishButtonProps.disabled}
            className={styles.button}
          />
        )}
      </div>
    </div>
  );
};

export default OnboardingHeader;
