import { useEffect, useRef, useState } from "react";
import { CommunicationError } from "../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../main-api";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Toast } from "primereact/toast";
import styles from "./styles.module.scss";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { Card } from "primereact/card";
import { Link } from "react-router-dom";
import { TERMS_AND_CONDITIONS_ROUTE } from "../../../terms-and-conditions/terms_and_conditions_route";
import { PRIVACY_POLICY_ROUTE } from "../../../privacy-policy/privacy-policy-routes";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { throwError } from "../../../../../throw-error";
import { LINK_CLASSNAME } from "../../../../../ui/links";
import { HEADINGS_CLASS_NAMES } from "../../../../../ui/headings";

const AccountingFirmSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().nullable(),
  official_id: z.string().nullable(),
  address: z.string().nullable(),
  homepage_url: z.string().nullable(),
  phone: z.string().nullable(),
});

type AccountingFirm = z.infer<typeof AccountingFirmSchema>;

const GERMAN_TRANSLATIONS = {
  newPassword: "Ihr neues Passwort",
  confirmAndCreateAccount: "Bestätigen und Konto erstellen",
  acceptTerms: "Ich akzeptiere die ",
  termsAndConditions: "AGB",
  privacyPolicy: "Datenschutzbestimmungen",
  acceptPrivacyPolicy: "Ich akzeptiere die ",
  acceptAuthorizationA: `Hiermit ermächtige ich `,
  acceptAuthorizationB: `, Handelsregistereintrag`,
  acceptAuthorizationC: ` meine GDPdU-Daten bei Kanzlei21 hochzuladen und auf die Banktransaktionsdaten für Buchhaltungszwecke zuzugreifen.`,
  definePassword: "Passwort festlegen",
  acceptInvite: "Einladung annehmen",
  minLengthRequirement: "Passwort muss mindestens 8 Zeichen enthalten",
  maxLengthRequirement: "Passwort darf höchstens 32 Zeichen enthalten",
  mustAgreeTerms: "Sie müssen den AGB zustimmen",
  mustAgreePrivacy: "Sie müssen den Datenschutzbestimmungen zustimmen",
  mustAgreeAuthorization: "Sie müssen die Autorisierungsanfrage akzeptieren.",
  about: "Über",
  close: "Schließen",
  name: "Name",
  clientId: "Handelsregistereintrag",
  email: "E-Mail",
  phone: "Telefon",
  address: "Adresse",
  homePageLink: "Homepage-Link",
};

const ENGLISH_TRANSLATIONS = {
  newPassword: "Your new password",
  confirmAndCreateAccount: "Confirm and create account",
  acceptTerms: "I accept the ",
  termsAndConditions: "Terms and Conditions",
  privacyPolicy: "Privacy Policy",
  acceptPrivacyPolicy: "I accept the ",
  acceptAuthorizationA: `I hereby authorize `,
  acceptAuthorizationB: `, Handelsregistereintrag `,
  acceptAuthorizationC: ` to upload my GDPdU data to Kanzlei 21 and to access the bank transactions data for accounting purposes`,
  definePassword: "Define your password",
  acceptInvite: "Accept invite",
  minLengthRequirement: "Password must be at least 8 characters",
  maxLengthRequirement: "Password must be at most 32 characters",
  mustAgreeTerms: "You must agree to the Terms and Conditions",
  mustAgreePrivacy: "You must agree to the Privacy Policy",
  mustAgreeAuthorization: "You must accept the Authorization request",
  about: "About",
  close: "Close",
  name: "Name",
  clientId: "Client ID",
  email: "Email",
  phone: "Phone",
  address: "Address",
  homePageLink: "Home Page Link",
};

export function AcceptInviteForm(props: { onFinish: () => void }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();
  const toastRef = useRef<Toast | null>(null);

  const token =
    new URLSearchParams(window.location.search).get("token") || throwError();

  const form = useForm({
    resolver: zodResolver(
      z.object({
        password: z
          .string()
          .min(8, translations.minLengthRequirement)
          .max(32, translations.maxLengthRequirement),
        accepted_terms_and_conditions: z
          .boolean()
          .refine((value) => value === true, translations.mustAgreeTerms),
        accepted_privacy_policy: z
          .boolean()
          .refine((value) => value === true, translations.mustAgreePrivacy),
        accounting_firm_was_authorized: z
          .boolean()
          .refine(
            (value) => value === true,
            translations.mustAgreeAuthorization
          ),
      })
    ),
    defaultValues: {
      password: "",
      accepted_terms_and_conditions: false,
      accepted_privacy_policy: false,
      accounting_firm_was_authorized: false,
    },
  });

  const [submissionState, setSubmissionState] = useState<{
    loading?: boolean;
  }>({});

  const [pageState, setPageState] = useState<{
    loading?: boolean;
    data?: AccountingFirm;
  }>({});

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    (async () => {
      if (pageState.data === undefined && !pageState.loading) {
        setPageState({ loading: true });

        const res = await mainApi.fetchJSON({
          path: `/accounting_firms/get_accounting_firm`,
          method: "POST",
          schema: z.object({
            status: z.literal(200),
            body: AccountingFirmSchema,
          }),
          body: {
            token,
          },
        });

        if (res.error) {
          toastRef.current?.show({
            severity: "error",
            summary: getCommunicationErrorTitle(res.error),
            detail: getCommunicationErrorMessage(res.error),
          });

          return;
        }

        setPageState({ data: res.response.body });
      }
    })();
  }, [mainApi, pageState, token]);

  if (pageState.loading) {
    return <ProgressSpinner className={styles.page} />;
  }

  const data = pageState.data;

  if (!data) {
    return;
  }

  return (
    <>
      <Toast ref={toastRef} />
      <h1
        className={`${HEADINGS_CLASS_NAMES.h1} ${styles.marginBottomSpacer2}`}
      >
        {translations.acceptInvite}
      </h1>
      <Card>
        <form
          onSubmit={form.handleSubmit(async (data) => {
            setSubmissionState({ loading: true });

            if (!token) {
              toastRef.current?.show({
                severity: "error",
                summary: "Error",
                detail: getCommunicationErrorMessage(
                  CommunicationError.UnexpectedResponse
                ),
              });
              return;
            }

            const res = await mainApi.fetchJSON({
              path: `/mobile_app_users/accept_invite`,
              method: "POST",
              schema: z.object({
                status: z.literal(200),
              }),
              body: {
                ...data,
                token,
              },
            });

            setSubmissionState({ loading: false });

            if (res.error) {
              toastRef.current?.show({
                severity: "error",
                summary: getCommunicationErrorTitle(res.error),
                detail: getCommunicationErrorMessage(res.error),
              });

              return;
            }

            props.onFinish();
          })}
        >
          <h2
            className={`${styles.marginBottomSpacer} ${HEADINGS_CLASS_NAMES.h2}`}
          >
            {translations.definePassword}
          </h2>
          <div className={styles.marginBottomSpacer2}>
            <Controller
              name="password"
              control={form.control}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <div>
                      <Password
                        id={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        placeholder={translations.newPassword}
                        toggleMask
                        className={styles.width100Percent}
                        inputClassName={classNames(styles.width100Percent, {
                          "p-invalid": fieldState.invalid,
                        })}
                        pt={{
                          iconField: {
                            root: { className: styles.width100Percent },
                          },
                        }}
                      />
                    </div>
                    {fieldState.error ? (
                      <span className={styles.inputErrorLabel}>
                        {fieldState.error.message}
                      </span>
                    ) : null}
                  </>
                );
              }}
            />
          </div>

          <div className={styles.checkboxWrapper}>
            <Controller
              name="accepted_privacy_policy"
              control={form.control}
              render={({ field, fieldState }) => (
                <div className="p-field-checkbox">
                  <Checkbox
                    inputId="accepted_privacy_policy"
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                  />
                  <label
                    htmlFor="accepted_privacy_policy"
                    className={styles.label}
                  >
                    {translations.acceptPrivacyPolicy}
                    <Link
                      to={PRIVACY_POLICY_ROUTE.getHref()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translations.privacyPolicy}
                    </Link>
                  </label>
                  {fieldState.error && (
                    <span className={styles.inputErrorLabel}>
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <div className={styles.marginBottomSpacer}>
            <Controller
              name="accepted_terms_and_conditions"
              control={form.control}
              render={({ field, fieldState }) => (
                <div className="p-field-checkbox">
                  <Checkbox
                    inputId="accepted_terms_and_conditions"
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                  />
                  <label
                    htmlFor="accepted_terms_and_conditions"
                    className={styles.label}
                  >
                    {translations.acceptTerms}
                    <Link
                      to={TERMS_AND_CONDITIONS_ROUTE.getHref()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {translations.termsAndConditions}
                    </Link>
                  </label>
                  {fieldState.error && (
                    <span className={styles.inputErrorLabel}>
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <div className={styles.marginBottomSpacer}>
            <Controller
              name="accounting_firm_was_authorized"
              control={form.control}
              render={({ field, fieldState }) => (
                <div className="p-field-checkbox">
                  <Checkbox
                    inputId="accounting_firm_was_authorized"
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                  />
                  <label
                    htmlFor="accounting_firm_was_authorized"
                    className={styles.label}
                  >
                    {translations.acceptAuthorizationA}
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                      }}
                      className={LINK_CLASSNAME}
                    >
                      {data.name}
                    </span>

                    {translations.acceptAuthorizationB}
                    {data.official_id && `(${data.official_id})`}
                    {translations.acceptAuthorizationC}
                  </label>
                  {fieldState.error && (
                    <span className={styles.inputErrorLabel}>
                      {fieldState.error.message}
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <Button
            loading={submissionState.loading}
            type="submit"
            label={translations.confirmAndCreateAccount}
            severity={"success"}
          />
        </form>
      </Card>

      <Dialog
        className={styles.filtersOverlayPanel}
        visible={showModal}
        draggable={false}
        onHide={() => {
          setShowModal(false);
        }}
        header={`${translations.about} ${data.name} ${data.official_id ? `(${data.official_id})` : ""}`}
        footer={() => (
          <div>
            <Button
              label={translations.close}
              onClick={() => setShowModal(false)}
            />
          </div>
        )}
      >
        <div>
          {translations.name}: <span> {data.name}</span>
        </div>
        {data.official_id && (
          <div>
            {translations.clientId}: <span> {data.official_id}</span>
          </div>
        )}
        {data.email && (
          <div>
            {translations.email}: <span> {data.email}</span>
          </div>
        )}
        {data.phone && (
          <div>
            {translations.phone}: <span> {data.phone}</span>
          </div>
        )}
        {data.address && (
          <div>
            {translations.address}: <span> {data.address}</span>
          </div>
        )}
        {data.homepage_url && (
          <div>
            {translations.homePageLink}:{" "}
            <a
              href={data.homepage_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {data.homepage_url}
            </a>
          </div>
        )}
      </Dialog>
    </>
  );
}
