import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import styles from "./invited-accounting-clients-loader.module.scss";
import { z } from "zod";
import { useMainApi } from "../../../../../../main-api";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../communication-errors/communication-error-messages";

export const InvitedAccountingClientSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  last_invitation_email_sent_at: z.coerce.date().nullable(),
  permission_granted: z.boolean(),
  status: z.union([
    z.literal("AWAITING_CONSENT"),
    z.literal("CONSENT_GIVEN"),
    z.literal("AWAITING_BANK_ACCOUNT_CONNECTION"),
    z.literal("ACCOUNTS_NEED_SETUP"),
  ]),
  expiration_date: z.coerce.date().nullable(),
});

export type InvitedAccountingClient = z.TypeOf<
  typeof InvitedAccountingClientSchema
>;

export function InvitedAccountingClientsLoader(props: {
  toast: MutableRefObject<Toast | null>;
  children: (renderProps: {
    accountingClients: InvitedAccountingClient[];
    onAccountingClientCreated: (
      accountingClient: InvitedAccountingClient
    ) => void;
    onAccountingClientUpdated: (
      accountingClient: InvitedAccountingClient
    ) => void;
  }) => ReactNode;
}) {
  const mainApi = useMainApi();

  const [state, setState] = useState<{
    loading?: boolean;
    data?: InvitedAccountingClient[];
  }>({});

  useEffect(() => {
    (async () => {
      const res = await mainApi.fetchJSON({
        method: "GET",
        path: "/accounting_clients/onboarding",
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            accounting_clients: z.array(InvitedAccountingClientSchema),
          }),
        }),
      });

      if (res.error) {
        props.toast.current!.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        setState({});
        return;
      }

      setState({
        loading: false,
        data: res.response.body.accounting_clients,
      });
    })();
  }, [mainApi, props.toast]);

  const onAccountingClientCreated = useCallback(
    (accountingClient: InvitedAccountingClient) => {
      setState((state) => {
        if (!state.data) {
          throw Error();
        }

        return { ...state, data: [accountingClient, ...state.data] };
      });
    },
    []
  );

  const onAccountingClientUpdated = useCallback(
    (accountingClient: InvitedAccountingClient) => {
      setState((state) => {
        if (!state.data) {
          throw Error();
        }

        return {
          ...state,
          data: state.data.map((a) =>
            a.id === accountingClient.id ? accountingClient : a
          ),
        };
      });
    },
    []
  );

  if (state.loading) {
    return (
      <div className={styles.loading}>
        <ProgressSpinner />
      </div>
    );
  }

  if (!state.data) {
    return null;
  }

  return (
    <>
      {props.children({
        accountingClients: state.data,
        onAccountingClientCreated,
        onAccountingClientUpdated,
      })}
    </>
  );
}
