import { QuickFilters } from "./quick-filters/quick-filters";
import { Filters } from "./filters/filters";
import { ExportCsvDialog } from "./export-csv/export-csv-dialog";

export function NavBar() {
  return (
    <>
      <div className={"flex justify-between gap-20"}>
        <div className={"flex-1 flex gap-2"}>
          <QuickFilters />
        </div>
        <div>
          <div className={"flex gap-2"}>
            <ExportCsvDialog />
            <Filters />
          </div>
        </div>
      </div>
    </>
  );
}
