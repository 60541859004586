import { useState, useEffect, useRef, useCallback } from "react";
import { useMainApi } from "../../../../../main-api";
import { z } from "zod";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../communication-errors/communication-error-messages";
import { Toast } from "primereact/toast";
import styles from "./invited-accounting-client-loader.module.scss";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { useAccountingClientId } from "../accounting-client-id";

const GERMAN_TRANSLATIONS = {
  notFound: "Nicht gefunden",
  notFoundMesage:
    "Der gewünschte Buchhaltungsmandant konnte nicht gefunden werden",
};

const ENGLISH_TRANSLATIONS = {
  notFound: "Not Found",
  notFoundMesage: "Could not find requested accounting client",
};

export const AccountingClientSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  client_number: z.string().nullable(),
  system_of_accounts: z.string().nullable(),
  vat_id: z.string().nullable(),
  last_bank_connection_email_sent_at: z.coerce.date().nullable(),
  has_gdpdu_upload: z.boolean(),
});

export type AccountingClient = z.infer<typeof AccountingClientSchema>;

export const FormOptionsSchema = z.object({
  system_of_accounts_types: z.array(
    z.object({
      id: z.string(),
      system_of_accounts_number: z.number(),
      name: z.string(),
    })
  ),
});

export type FormOptions = z.infer<typeof FormOptionsSchema>;

export const InvitedAccountingClientLoader = ({
  children,
}: {
  children: (props: {
    formOptions: FormOptions;
    accountingClientId: string;
    accountingClient: AccountingClient;
    setAccountingClient: (accountingClient: AccountingClient) => void;
  }) => React.ReactNode;
}) => {
  const accountingClientId = useAccountingClientId();

  const mainApi = useMainApi();
  const toast = useRef<Toast | null>(null);

  const [formOptionsState, setFormOptionsState] = useState<{
    loading?: boolean;
    data?: FormOptions;
  }>({});

  const [accountingClientState, setAccountingClientState] = useState<{
    loading?: boolean;
    data?: AccountingClient;
  }>({});

  const setAccountingClient = useCallback(
    (accountingClient: AccountingClient) => {
      setAccountingClientState({ data: accountingClient });
    },
    []
  );

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  useEffect(() => {
    const fetchFormOptions = async () => {
      setFormOptionsState({ loading: true });

      const res = await mainApi.fetchJSON({
        path: `/accounting_clients/form_options`,
        method: "GET",
        schema: z.object({
          status: z.literal(200),
          body: FormOptionsSchema,
        }),
      });

      if (res.error) {
        toast.current!.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        setFormOptionsState({});
        return;
      }

      setFormOptionsState({ data: res.response.body });
    };

    const fetchClient = async () => {
      setAccountingClientState({ loading: true });

      const res = await mainApi.fetchJSON({
        path: `/accounting_clients/onboarding/${accountingClientId}`,
        method: "GET",
        schema: z.union([
          z.object({
            status: z.literal(404),
          }),
          z.object({
            status: z.literal(200),
            body: AccountingClientSchema,
          }),
        ]),
      });

      if (res.error) {
        toast.current!.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        setAccountingClientState({});
        return;
      }

      if (res.response.status === 404) {
        toast.current!.show({
          severity: "error",
          summary: translations.notFound,
          detail: translations.notFoundMesage,
        });

        setAccountingClientState({});
        return;
      }

      setAccountingClientState({ data: res.response.body });
    };

    Promise.all([fetchFormOptions(), fetchClient()]);
  }, [
    accountingClientId,
    mainApi,
    translations.notFound,
    translations.notFoundMesage,
  ]);

  return (
    <>
      <Toast ref={toast} />
      <>
        {(() => {
          if (formOptionsState.loading || accountingClientState.loading) {
            return (
              <div className={styles.loadingContainer}>
                <ProgressSpinner />
              </div>
            );
          }

          if (
            formOptionsState.data === undefined ||
            accountingClientState.data === undefined
          ) {
            return <></>;
          }

          return children({
            formOptions: formOptionsState.data,
            accountingClientId,
            accountingClient: accountingClientState.data,
            setAccountingClient,
          });
        })()}
      </>
    </>
  );
};
