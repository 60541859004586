import { useState } from "react";
import { CreateFinApiItem } from "./create-finapi-item";
import { ListFinApiItems } from "./list-finapi-accounts";
import styles from "./finapi.module.scss";
import { Divider } from "primereact/divider";
import { CommunicationError } from "../../../../communication-errors/communication-errors";
import { z } from "zod";

export const FinApiAccountSchema = z.object({
  account_iban: z.string().nullable(),
  account_name: z.string().nullable(),
});

export const FinApiBankConnectionSchema = z.object({
  id: z.string(),
  bank_name: z.string().nullable(),
  bank_logo_url: z.string().nullable(),
  accounts: z.array(FinApiAccountSchema),
  user_action_required: z.boolean(),
});
export const FinApiResponseSchema = z.object({
  status: z.literal(200),
  body: z.array(FinApiBankConnectionSchema),
});

export type FinApiAccount = z.infer<typeof FinApiAccountSchema>;
export type FinApiBankConnection = z.infer<typeof FinApiBankConnectionSchema>;

export type FinApiState = {
  loading?: boolean;
  error?: CommunicationError;
  data?: FinApiBankConnection[];
};

export function FinApi() {
  const [state, setState] = useState<FinApiState>({});

  return (
    <>
      <div className={styles.container}>
        <ListFinApiItems finApiState={state} setFinApiState={setState} />
        <Divider />
        <CreateFinApiItem finApiState={state} setFinApiState={setState} />
      </div>
    </>
  );
}
