import styles from "./styles.module.scss";
import { Card } from "primereact/card";
import select_client from "./images/select_client.png";
import go_to_bank_bookings from "./images/go_to_bank_bookings.png";
import set_filters from "./images/set_filters.png";
import export_preview from "./images/export_preview.png";
import success_message from "./images/success_message.png";
import download_xml_template from "./images/download_xml_template.png";
import go_to_imports_section_a from "./images/go_to_imports_section_a.png";
import go_to_imports_section_b from "./images/go_to_imports_section_b.png";
import select_kanzlei21_template from "./images/select_kanzlei21_template.png";
import start_import from "./images/start_import.png";
import select_file_to_import from "./images/select_file_to_import.png";
import finish_import from "./images/finish_import.png";
import { HEADINGS_CLASS_NAMES } from "../../../../ui/headings";

const ExportBookingToEdreweEnglish = () => {
  return (
    <Card className={styles.tutorialsCard}>
      <h1 className={HEADINGS_CLASS_NAMES.h1}>
        Tutorial for exporting bookings from Kanzlei21 and reimporting them into
        Edrewe
      </h1>
      <p>
        This tutorial explains how to export bank bookings from the Kanzlei21
        web application in CSV format and reimport the bookings into Edrewe.
      </p>

      <ol className={styles.decimalList}>
        <li className={styles.section}>
          <h2 className={HEADINGS_CLASS_NAMES.h2}>
            Steps for Exporting in Kanzlei21:
          </h2>
          <ol className={styles.decimalList}>
            <li>
              <strong>Login:</strong> Log into the Kanzlei21 web application.
            </li>
            <li>
              <strong>Select client:</strong> Starting at the dashboard screen,
              select a client from the sidebar dropdown menu.
            </li>
            <img
              src={select_client}
              className={styles.screenshot}
              alt="Select Client Screenshot"
            />
            <li>
              <strong>Go to Bank Bookings:</strong> With a client selected,
              click on Bank Bookings on the sidebar. By default, you'll see all
              the bank bookings from the default bank account (usually account
              1200).
            </li>
            <img
              src={go_to_bank_bookings}
              className={styles.screenshot}
              alt="Bank Bookings Screenshot"
            />
            <li>
              <strong>Set filters:</strong> If needed, you can select a
              different bank account, refine the dates, and set other filters
              from the topbar options.
              <em> Note:</em> For Edrewe import, export bookings for one
              calendar year at a time. (A Year filter will be available.)
            </li>
            <img
              src={set_filters}
              className={styles.screenshot}
              alt="Filter Screenshot"
            />
            <li>
              <strong>Export preview:</strong> Click on the Export CSV button in
              the top menu. A popup appears with a summary of the bookings that
              will be exported. If any adjustments are needed, close the popup
              (will change to “click on the Cancel button”), make the
              adjustments needed, and then click on Export CSV again. Once all
              configurations are as desired, click on Export CSV (Export CSV
              now). A CSV file is created and downloaded to your computer.
            </li>
            <img
              src={export_preview}
              className={styles.screenshot}
              alt="Export CSV Screenshot"
            />
            <li>
              <strong>Success message:</strong> A success popup will appear. Do
              not close this popup yet. The created CSV file has the following
              columns: Umsatz, Soll-Kennzeichen, Konto, Belegdatum, Gegenkonto,
              IBAN, Buchungstext, Buchungsschlüssel.
            </li>
            <img
              src={success_message}
              className={styles.screenshot}
              alt="Success Message Screenshot"
            />
            <li>
              <strong>Download XML template:</strong> From the Success message
              popup, download the XML template for importing into Edrewe. To do
              this, click on the hyperlink at the text “XML template needed for
              import into Edrewe. To import booking into Edrewe, please use our
              XML template, which you can download here.” (This part will
              change, we will have the new message). If you close the popup
              before downloading the XML template, you can also get it by
              clicking here.
            </li>
            <img
              src={download_xml_template}
              className={styles.screenshot}
              alt="XML Download Screenshot"
            />
          </ol>
        </li>

        <li className={styles.section}>
          <h2 className={HEADINGS_CLASS_NAMES.h2}>
            Steps for Importing in Edrewe:
          </h2>
          <ol className={styles.decimalList}>
            <li>
              <strong>Login:</strong> Log into the Edrewe application.
            </li>
            <li>
              <strong>Go to the import section:</strong> Make sure you're in the{" "}
              <em>Buchführung</em> tab on the sidebar panel. From here, do one
              of the following:
              <img
                src={go_to_imports_section_a}
                className={styles.screenshot}
                alt="Access Import Screenshot"
              />
              <ol className={`${styles.subStep} ${styles.decimalList}`}>
                <li>
                  If the Import option on the sidebar is active, just click on
                  it.
                </li>
                <li>
                  If the Import option is not active, choose a <em>Mandant</em>{" "}
                  from the main screen. Then, double-click on a year (e.g.,
                  2024). At this point, it doesn’t matter which <em>Mandant</em>{" "}
                  and which year. Now, the Import option on the sidebar should
                  be active. Click on it.
                </li>
              </ol>
              <img
                src={go_to_imports_section_b}
                className={styles.screenshot}
                alt="Mandant Selection Screenshot"
              />
            </li>
            <li>
              <strong>Select Kanzlei21 template:</strong> Go to the{" "}
              <em>Buchungen & Sonstige Daten</em> tab. Click on{" "}
              <em>Austausch</em> from the top menu. Select{" "}
              <em>Laden aus einer Datei</em> On the File Open window, choose the
              XML template from Kanzlei21 (Name) you downloaded in the previous
              step. Click on <em>Öffnen</em>. This will upload the XML template.
              A success message will appear. You can close it.
            </li>
            <img
              src={select_kanzlei21_template}
              className={styles.screenshot}
              alt="XML Template Upload Screenshot"
            />
            <li>
              <strong>Start import:</strong> Under the import templates, a new
              template called <em>Kanzlei21</em> has been created. Select it
              with a single click. With the Kanzlei21 template selected, click
              on <em>Auswahl Importdaten</em> in the top menu. A popup{" "}
              <em>Import aus Fremddaten</em> will appear.
            </li>
            <img
              src={start_import}
              className={styles.screenshot}
              alt="Start Import Screenshot"
            />
            <li>
              <strong>Select file to import:</strong> Click on{" "}
              <em>Durchsuchen</em> to choose the right folder where you
              downloaded the CSV file from Kanzlei21 (probably your Downloads
              folder) and click <em>Öffnen</em> Find the right file. The file
              will have a format like{" "}
              <em>bookings_YYYY-MM-DD_HH-MM-SS_XXXXXX</em> (lets change the name
              format to <em>Buchungen</em>) Select the file by clicking on the
              checkbox, and then click on <em>Weiter</em>.
            </li>
            <img
              src={select_file_to_import}
              className={styles.screenshot}
              alt="File Selection Screenshot"
            />
            <li>
              <strong>Check settings:</strong> On the next screen, just click on{" "}
              <em>Weiter</em> unless you want to make any changes (advanced).
              The following screen shows a preview of the files. You can make
              modifications here before importing. Once all is ok, click on{" "}
              <em>Weiter</em>.
            </li>
            <li>
              <strong>Finish import:</strong> The last screen shows again a
              preview before importing. Click on <em>Fertigstellen</em>. A
              success message will appear. You can close it.
            </li>
            <img
              src={finish_import}
              className={styles.screenshot}
              alt="Finish Import Screenshot"
            />
            <li>
              <strong>Done!</strong> You have successfully imported your
              bookings from Kanzlei21 into Edrewe. You can find them by going
              into the right <em>Mandant</em> and year, and clicking on{" "}
              <em>Buchungsprotokoll</em> on the sidebar. They should be at the
              bottom of the list.
            </li>
          </ol>
        </li>
      </ol>
    </Card>
  );
};

export default ExportBookingToEdreweEnglish;
