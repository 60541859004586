import { ReactNode, createContext, useState } from "react";
import { DEFAULT_SORT_COLUMN } from "./sorting-constants";

export type SortColumn = {
  columnKey:
    | "account_number"
    | "account_name"
    | "is_connected_to_bank_account"
    | "total_transactions";
  direction: "ASC" | "DESC";
};

export type SortingContextType = {
  sortColumn: SortColumn;
  setSortColumn: (column: SortColumn) => void;
};

function useContextValue() {
  const [sortColumn, setSortColumn] = useState<SortColumn>(DEFAULT_SORT_COLUMN);

  return {
    sortColumn,
    setSortColumn,
  };
}

export const PaymentAccountsSortingContext = createContext<
  ReturnType<typeof useContextValue> | undefined
>(undefined);

export function PaymentAccountsSortingContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const value = useContextValue();
  return (
    <PaymentAccountsSortingContext.Provider value={value}>
      {children}
    </PaymentAccountsSortingContext.Provider>
  );
}
