import { ProgressSpinner } from "primereact/progressspinner";
import { useContext, useEffect, useId } from "react";
import { useNavigate } from "react-router-dom";
import { AccountingClientsContext } from "../../../menu/accounting-clients-context";
import { throwError } from "../../../../../throw-error";

export function Finishing() {
  const navigate = useNavigate();

  const { refreshAccountingClients } =
    useContext(AccountingClientsContext) || throwError();

  const id = useId();

  useEffect(() => {
    refreshAccountingClients();

    navigate("/accountant");
  }, [navigate, refreshAccountingClients]);

  return (
    <>
      <style>
        {`[id="${id}"] {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }`}
      </style>
      <div id={id}>
        <ProgressSpinner />
      </div>
    </>
  );
}
