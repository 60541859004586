import { Button } from "primereact/button";

import styles from "./invited-accounting-client-details-panel.module.scss";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useId, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";

import { Toast } from "primereact/toast";

import {
  MainApiFetchJSONResponse,
  useMainApi,
} from "../../../../../../main-api";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import {
  InvitedAccountingClient,
  InvitedAccountingClientSchema,
} from "../loader/invited-accounting-clients-loader";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../communication-errors/communication-error-messages";
import { HEADINGS_CLASS_NAMES } from "../../../../../../ui/headings";

const ENGLISH_TRANSLATIONS = {
  name: "Name",
  email: "Email",
  createNewClient: "Create New Client",
  updateClientDetails: "Update Client Details",
  sendInvitation: "Send invitation email",
  resendInvitation: "Resend invitation email",
  saved: "Saved",
  cancel: "Cancel",
  required: "Required",
  mustBeAnEmail: "Must be a valid email",
  duplicateEmailAddress: "Duplicate email address",
  duplicateEmailAddressDetail:
    "A client with this email address already exists",
  confirmationHeader: "Send Invitation Email",
  confirmationMessage: (name: string) =>
    `This will send an invitation email to ${name}. Proceed?`,
  yes: "Yes",
  no: "No",
  invitationSent: "Invitation Email Sent",
  invitationSentDetail: (name: string) =>
    `Invitation email has been sent to ${name} successfully`,
  rejected: "Rejected",
  rejectedDetail: "Invitation was not sent",
};

const GERMAN_TRANSLATIONS = {
  name: "Name",
  email: "Email",
  createNewClient: "Neuen Mandanten erstellen",
  updateClientDetails: "Mandantendetails aktualisieren",
  sendInvitation: "Einladungsmail senden",
  resendInvitation: "Einladungsmail erneut senden",
  saved: "Gespeichert",
  cancel: "Abbrechen",
  required: "Pflichtfeld",
  mustBeAnEmail: "E-Mail muss eine gueltige E-Mail-Adresse sein",
  duplicateEmailAddress: "Doppelte E-Mail-Adresse",
  duplicateEmailAddressDetail:
    "Ein Mandant mit dieser E-Mail-Adresse ist bereits vorhanden",
  confirmationHeader: "Einladungsmail senden",
  confirmationMessage: (name: string) =>
    `Es wird eine Einladungsmail an ${name} gesendet. Fortfahren?`,
  yes: "Ja",
  no: "Nein",
  invitationSent: "Einladungmail Gesendet",
  invitationSentDetail: (name: string) =>
    `Einladungsmail wurde erfolgreich an ${name} gesendet`,
  rejected: "Abgelehnt",
  rejectedDetail: "Einladung wurde nicht gesendet",
};

export function InvitedAccountingClientDetailsPanel({
  selectedAccountingClient,
  onCancel,
  onAccountingClientCreated,
  onAccountingClientUpdated,
}: {
  selectedAccountingClient: InvitedAccountingClient | undefined;
  onAccountingClientCreated: (
    accountingClient: InvitedAccountingClient
  ) => void;
  onAccountingClientUpdated: (
    accountingClient: InvitedAccountingClient
  ) => void;
  onCancel: () => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toast = useRef<Toast | null>(null);
  const mainApi = useMainApi();

  const idPrefix = useId();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const form = useForm({
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, translations.required),
        email: z.string().email(translations.mustBeAnEmail),
      })
    ),
    defaultValues: selectedAccountingClient,
  });

  useEffect(() => {
    if (selectedAccountingClient) {
      form.reset(selectedAccountingClient);
    } else {
      form.reset({
        name: "",
        email: "",
      });
    }
  }, [form, selectedAccountingClient]);

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <form
        onSubmit={form.handleSubmit(async (data) => {
          confirmDialog({
            message: translations.confirmationMessage(data.name),
            header: translations.confirmationHeader,
            icon: "pi pi-question-circle",
            acceptLabel: translations.yes,
            rejectLabel: translations.no,
            accept: async () => {
              setIsSubmitting(true);

              let res: MainApiFetchJSONResponse<
                | {
                    status: 200;
                    body: InvitedAccountingClient;
                  }
                | { status: 409; body: { detail: "email-is-taken" } }
              >;

              if (selectedAccountingClient) {
                res = await mainApi.fetchJSON({
                  method: "PUT",
                  path: `/accounting_clients/onboarding/${selectedAccountingClient.id}`,
                  body: data,
                  schema: z.union([
                    z.object({
                      status: z.literal(409),
                      body: z.object({
                        detail: z.literal("email-is-taken"),
                      }),
                    }),
                    z.object({
                      status: z.literal(200),
                      body: InvitedAccountingClientSchema,
                    }),
                  ]),
                });
              } else {
                res = await mainApi.fetchJSON({
                  method: "POST",
                  path: "/accounting_clients/onboarding",
                  body: data,
                  schema: z.union([
                    z.object({
                      status: z.literal(409),
                      body: z.object({
                        detail: z.literal("email-is-taken"),
                      }),
                    }),
                    z.object({
                      status: z.literal(200),
                      body: InvitedAccountingClientSchema,
                    }),
                  ]),
                });
              }

              setIsSubmitting(false);

              if (res.error) {
                toast.current!.show({
                  severity: "error",
                  summary: getCommunicationErrorTitle(res.error),
                  detail: getCommunicationErrorMessage(res.error),
                });

                return;
              }

              if (res.response.status == 409) {
                if (res.response.body.detail === "email-is-taken") {
                  toast.current!.show({
                    severity: "error",
                    summary: translations.duplicateEmailAddress,
                    detail: translations.duplicateEmailAddressDetail,
                  });

                  return;
                } else {
                  throw new Error();
                }
              }

              toast.current!.show({
                severity: "success",
                summary: translations.invitationSent,
                detail: translations.invitationSentDetail(data.name),
              });

              if (selectedAccountingClient) {
                onAccountingClientUpdated(res.response.body);
              } else {
                onAccountingClientCreated(res.response.body);
              }
            },
            reject: () => {
              toast.current!.show({
                severity: "warn",
                summary: translations.rejected,
                detail: translations.rejectedDetail,
              });
            },
          });
        })}
      >
        <div className={styles.header}>
          <div>
            <h3 className={HEADINGS_CLASS_NAMES.h3}>
              {selectedAccountingClient
                ? translations.updateClientDetails
                : translations.createNewClient}
            </h3>
          </div>
          <div className={styles.headerLeft}>
            <Button
              label={translations.cancel}
              severity={"danger"}
              onClick={() => onCancel()}
              type="button"
            />
            <Button
              disabled={
                isSubmitting ||
                (selectedAccountingClient
                  ? selectedAccountingClient.permission_granted
                  : false)
              }
              loading={isSubmitting}
              label={
                selectedAccountingClient
                  ? translations.resendInvitation
                  : translations.sendInvitation
              }
              severity={"success"}
              type="submit"
            />
          </div>
        </div>
        <div className={`${styles.row} ${styles.marginBottom}`}>
          <div
            className={`${styles.formField} ${styles.alignItemsFirstBaseline}`}
          >
            <label className={styles.label} htmlFor={`${idPrefix}-name`}>
              {translations.name}:
            </label>
            <div className={styles.inputField}>
              <Controller
                name="name"
                control={form.control}
                render={({ field, fieldState }) => (
                  <InputText
                    id={`${idPrefix}-name`}
                    className={fieldState.error ? "p-invalid" : ""}
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />

              {form.formState.errors.name && (
                <small className="p-error">
                  {form.formState.errors.name.message}
                </small>
              )}
            </div>
          </div>

          <div
            className={`${styles.formField} ${styles.alignItemsFirstBaseline}`}
          >
            <label className={styles.label} htmlFor={`${idPrefix}-email`}>
              {translations.email}:
            </label>
            <div className={styles.inputField}>
              <Controller
                name="email"
                control={form.control}
                render={({ field, fieldState }) => (
                  <InputText
                    id={`${idPrefix}-email`}
                    className={fieldState.error ? "p-invalid" : ""}
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />

              {form.formState.errors.email && (
                <small className="p-error">
                  {form.formState.errors.email.message}
                </small>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
