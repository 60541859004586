import {
  useParams,
  useNavigate,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import AccountingClientLoader from "./accountingClientLoader";
import { TabView, TabPanel } from "primereact/tabview";
import Gdpdu from "./gdpdu/gdpdu";
import AccountsOverview from "./accounts-overview/page";
import ClientInformation from "./basic-information/basic-information";
import styles from "./page.module.scss";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import BankInformation from "./bank-information/bank-information";

const GERMAN_TRANSLATIONS = {
  clientInformation: "Mandanteninformationen",
  bankAccounts: "Bankkonten",
  accountsOverview: "Kontenübersicht",
};

const ENGLISH_TRANSLATIONS = {
  clientInformation: "Client Information",
  bankAccounts: "Bank Accounts",
  accountsOverview: "Accounts Overview",
};

function Page() {
  const { accountingClientId } = useParams<{ accountingClientId?: string }>();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AccountingClientLoader>
      {(props) => {
        const gdpduTabPath = `/accountant/accounting-clients/${accountingClientId}/edit/gdpdu`;

        const tabPaths = (() => {
          const basePath = `/accountant/accounting-clients/${accountingClientId}/edit`;

          return [
            basePath,
            gdpduTabPath,
            `${basePath}/accounts-overview`,
            `${basePath}/bank-accounts`,
          ];
        })();

        const routes = (
          <Routes>
            <Route
              index
              element={
                <ClientInformation
                  formOptions={props.formOptions}
                  accountingClient={props.accountingClient}
                  setAccountingClient={props.setAccountingClient}
                />
              }
            />
            <Route
              path="gdpdu"
              element={
                <Gdpdu
                  accountingClient={props.accountingClient}
                  setAccountingClient={props.setAccountingClient}
                />
              }
            />
            <Route
              path="accounts-overview"
              element={
                <AccountsOverview accountingClient={props.accountingClient} />
              }
            />
            <Route
              path="bank-accounts"
              element={
                <BankInformation
                  accountingClient={props.accountingClient}
                  setAccountingClient={props.setAccountingClient}
                  switchToDocumentsTab={() => navigate(gdpduTabPath)}
                />
              }
            />
          </Routes>
        );

        return (
          <TabView
            className={styles.tabView}
            activeIndex={tabPaths.findIndex(
              (path) => location.pathname === path
            )}
            onTabChange={({ index }) => {
              const path = tabPaths[index];
              if (!path) {
                throw new Error();
              }
              navigate(path);
            }}
          >
            <TabPanel header={translations.clientInformation}>
              {routes}
            </TabPanel>
            <TabPanel header="GDPdU">{routes}</TabPanel>
            <TabPanel header={translations.accountsOverview}>{routes}</TabPanel>
            <TabPanel header={translations.bankAccounts}>{routes}</TabPanel>
          </TabView>
        );
      }}
    </AccountingClientLoader>
  );
}

export default Page;
