import { useRef, useContext, useState, useEffect } from "react";
import { z } from "zod";
import styles from "./basic-information.module.scss";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../../communication-errors/communication-error-messages";
import { ClientData } from "./form-sections/client-data";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { ContactInformation } from "./form-sections/contact-information";
import { AccountingClientContext as PageAccountingClientContext } from "../../accounting-client-context";
import { throwError } from "../../../../../../../throw-error";
import { useMainApi } from "../../../../../../../main-api";
import { GdpduContext } from "../../gdpdu-preview-context";
import OnboardingHeader from "../../onboarding-header";
import { useAccountingClientId } from "../../../accounting-client-id";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { AccountingClientsContext } from "../../../../../menu/accounting-clients-context";

const GERMAN_TRANSLATIONS = {
  save: "Speichern",
  success: "Erfolg",
  successDetail: "Buchhaltungsmandant erfolgreich gespeichert",
  duplicateClientNumber: "Doppelte Mandantennummer",
  duplicateClientNumberDetail:
    "Ein Mandant mit dieser Nummer existiert bereits",
  duplicateVatId: "Doppelte USt-IdNr.",
  duplicateVatIdDetail: "Ein Kunde mit dieser USt-IdNr. existiert bereits.",
  invalidResponse: "Ungültige Antwort",
  invalidResponseDetails: "Der Server hat eine ungültige Antwort zurückgegeben",
  clientNumberRequired: "Mandantennummer ist erforderlich",
  nameRequired: "Name ist erforderlich",
  emailRequired: "E-Mail ist erforderlich",
  emailInvalid: "Ungültige E-Mail-Adresse",
  phoneNumberRequired: "Telefonnummer ist erforderlich",
  vatIdIsTaken:
    "Ein Mandant mit dem gleichen Umsatzsteuer-Identifikationsnummer existiert bereits",
  selectSystemOfAccounts: "Bitte wählen Sie einen Kontenrahmen aus",
  invalidEmail: "Ungültige E-Mail-Adresse",
  vatIdRequired: "USt-IdNr. ist erforderlich",
  clientInformation: "Mandanteninformationen",
  confirmationHeader: "Neuen Mandanten erstellen",
  confirmationMessage: (name: string) =>
    `Dies wird einen neuen Mandanten mit dem Namen ${name} erstellen. Fortfahren?`,
  yes: "Ja",
  no: "Nein",
  rejected: "Abgelehnt",
  rejectedDetail: "Die Mandantenerstellung wurde abgelehnt.",
};

const ENGLISH_TRANSLATIONS = {
  save: "Save",
  success: "Success",
  successDetail: "Accounting client saved successfully",
  duplicateClientNumber: "Duplicate client number",
  duplicateClientNumberDetail: "A client with this number already exists",
  duplicateVatId: "Duplicate VAT ID",
  duplicateVatIdDetail: "A client with this VAT ID already exists",
  invalidResponse: "Invalid Response",
  invalidResponseDetails: "The server returned an invalid response. ",
  clientNumberRequired: "Client number is required",
  nameRequired: "Name is required",
  emailRequired: "Email is required",
  emailInvalid: "Invalid email address",
  phoneNumberRequired: "Phone number is required",
  vatIdIsTaken: "A client with the same VAT ID already exists",
  selectSystemOfAccounts: "Please select a system of accounts type",
  invalidEmail: "Invalid email address",
  vatIdRequired: "VAT ID is required",
  clientInformation: "Client Information",
  confirmationHeader: "Create New Client",
  confirmationMessage: (name: string) =>
    `This will create a new client called ${name}. Proceed?`,
  yes: "Yes",
  no: "No",
  rejected: "Rejected",
  rejectedDetail: "The client creation was rejected.",
};

export const AccountingClientFormSchema = (
  translations: typeof ENGLISH_TRANSLATIONS
) =>
  z.object({
    client_number: z.string().min(1, translations.clientNumberRequired),
    name: z.string().min(1, translations.nameRequired),
    system_of_accounts: z.string().uuid(translations.selectSystemOfAccounts),
    email: z.string().email(translations.invalidEmail).nullable(),
    vat_id: z.string().min(1, translations.vatIdRequired),
  });

export type AccountingClientFormValue = z.infer<
  ReturnType<typeof AccountingClientFormSchema>
>;

function BasicInformation(props: {
  onPrevStep: () => void;
  onNextStep: () => void;
}) {
  const accountingClientId = useAccountingClientId();

  const toastRef = useRef<Toast | null>(null);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const [isSubmitting, setSubmitting] = useState(false);

  const { accountingClient } =
    useContext(PageAccountingClientContext) || throwError();

  const { refreshAccountingClients } =
    useContext(AccountingClientsContext) || throwError();

  const { gdpduPreviewState } = useContext(GdpduContext) || throwError();

  if (!gdpduPreviewState) {
    throw new Error();
  }

  useEffect(() => {
    if (isSubmitting) {
      gdpduPreviewState;
    }
  }, [isSubmitting, gdpduPreviewState]);

  return (
    <div className={styles.page}>
      <OnboardingHeader
        titleProps={{
          text: translations.clientInformation,
          className: styles.maxWidth,
        }}
        previousButtonProps={{
          loading: isSubmitting,
          disabled: isSubmitting,
          onClick: () => {
            props.onPrevStep();
          },
        }}
        nextButtonProps={{
          loading: isSubmitting,
          disabled: isSubmitting,
          onClick: () => {
            confirmDialog({
              header: translations.confirmationHeader,
              message: translations.confirmationMessage(
                gdpduPreviewState.data.name
              ),
              icon: "pi pi-question-circle",
              acceptLabel: translations.yes,
              rejectLabel: translations.no,
              accept: async () => {
                setSubmitting(true);

                const formData = new FormData();
                formData.append("file", gdpduPreviewState.file);

                const res = await mainApi.fetch(
                  `/accounting_clients/onboarding/${accountingClientId}/confirm`,
                  [200, 409],
                  {
                    method: "POST",
                    body: formData,
                  }
                );
                setSubmitting(false);

                if (res.error) {
                  toastRef.current!.show({
                    severity: "error",
                    summary: getCommunicationErrorTitle(res.error),
                    detail: getCommunicationErrorMessage(res.error),
                  });

                  return;
                }

                if (res.response.status === 409) {
                  const responseData = await res.response.json();
                  let summary: string;
                  let detail: string;

                  if (responseData.detail === "client-number-is-taken") {
                    summary = translations.duplicateClientNumber;
                    detail = translations.duplicateClientNumberDetail;
                  } else if (responseData.detail === "vat-id-is-taken") {
                    summary = translations.duplicateVatId;
                    detail = translations.duplicateVatIdDetail;
                  } else {
                    throw new Error();
                  }

                  toastRef.current?.show({
                    severity: "warn",
                    summary,
                    detail,
                  });
                  return;
                }

                props.onNextStep();
                refreshAccountingClients();
              },
            });
          },
        }}
      />
      <Toast ref={toastRef} />
      <ConfirmDialog />
      <div className={`${styles.formSection}  ${styles.maxWidth}`}>
        <ClientData data={gdpduPreviewState.data} />
      </div>
      <div className={`${styles.formSection}  ${styles.maxWidth}`}>
        <ContactInformation
          data={{
            email: accountingClient.email,
          }}
        />
      </div>
    </div>
  );
}

export default BasicInformation;
