import {
  Dispatch,
  Fragment,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMainApi } from "../../../../main-api";
import styles from "./list-finapi-accounts.module.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { getCommunicationErrorMessage } from "../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { Divider } from "primereact/divider";

import {
  FinApiBankConnection,
  FinApiResponseSchema,
  FinApiState,
} from "./finapi";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { UpdateFinApiItem } from "./update-finapi-item/update-finapi-item";

const GERMAN_TRANSLATIONS = {
  noBankAccountsConnected: "Keine Konten verbunden",
  update: "Anmeldedaten müssen aktualisiert werden",
};

const ENGLISH_TRANSLATIONS = {
  noBankAccountsConnected: "No accounts connected",
  update: "Credentials need to be updated",
};

function FinApiItem(props: {
  toastRef: MutableRefObject<Toast | null>;
  bankConnection: FinApiBankConnection;
  setFinApiState: Dispatch<SetStateAction<FinApiState>>;
}) {
  const [isUpdatingItem, setIsUpdatingItem] = useState(false);
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const onClose = useCallback(() => {
    setIsUpdatingItem(false);
  }, []);

  return (
    <div className={styles.item}>
      <div className={styles.row1}>
        <div className={styles.bankInfo}>
          {props.bankConnection.bank_logo_url && (
            <img
              className={styles.bankLogo}
              src={props.bankConnection.bank_logo_url}
              alt={`${props.bankConnection.bank_name} logo`}
            />
          )}
          <p className={styles.bankName}>{props.bankConnection.bank_name}</p>
        </div>
        <div className={styles.bankActions}>
          {props.bankConnection.user_action_required ? (
            <Button
              loading={isUpdatingItem}
              disabled={isUpdatingItem}
              label={translations.update}
              severity={"danger"}
              onClick={() => {
                setIsUpdatingItem(true);
              }}
            />
          ) : null}
          {isUpdatingItem && (
            <UpdateFinApiItem
              setFinApiState={props.setFinApiState}
              bankConnection={props.bankConnection}
              toastRef={props.toastRef}
              onClose={onClose}
            />
          )}
        </div>
      </div>
      <div className={styles.row2}>
        <ul className="list-disc list-inside">
          {props.bankConnection.accounts.map((account, idx) => (
            <li key={idx}>
              {account.account_name} - {account.account_iban}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export function ListFinApiItems({
  finApiState,
  setFinApiState,
}: {
  finApiState: FinApiState;
  setFinApiState: Dispatch<SetStateAction<FinApiState>>;
}) {
  const toastRef = useRef<Toast | null>(null);
  const currentLanguage = useCurrentLanguage();
  const mainApi = useMainApi();

  useEffect(() => {
    (async () => {
      setFinApiState({ loading: true });

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: "/bank-accounts/finapi",
        schema: FinApiResponseSchema,
      });

      setFinApiState({ loading: false });

      if (res.error) {
        setFinApiState({ error: res.error });
        return;
      }

      setFinApiState({ data: res.response.body });
    })();
  }, [mainApi, setFinApiState]);

  return (
    <>
      <Toast ref={toastRef} />
      {(() => {
        if (finApiState.loading) {
          return (
            <div className={`${styles.center}`}>
              <ProgressSpinner className={styles.loading} />
            </div>
          );
        }

        if (finApiState.error) {
          return (
            <div className={`${styles.center}`}>
              <div className={styles.errorMessage}>
                {getCommunicationErrorMessage(finApiState.error)}
              </div>
            </div>
          );
        }

        if (!finApiState.data) {
          return;
        }

        if (finApiState.data.length === 0) {
          return (
            <div className={`${styles.center} ${styles.noDataText}`}>
              <p>
                {currentLanguage === SupportedLanguage.German
                  ? GERMAN_TRANSLATIONS.noBankAccountsConnected
                  : ENGLISH_TRANSLATIONS.noBankAccountsConnected}
              </p>
            </div>
          );
        }

        return (
          <div className={styles.list}>
            {finApiState.data.map((bankConnection, index) => (
              <Fragment key={bankConnection.id}>
                {index !== 0 && <Divider />}
                <FinApiItem
                  toastRef={toastRef}
                  bankConnection={bankConnection}
                  setFinApiState={setFinApiState}
                />
              </Fragment>
            ))}
          </div>
        );
      })()}
    </>
  );
}
