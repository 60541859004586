import { Controller, UseFormReturn } from "react-hook-form";
import { EditAccountFormValue } from "../details-mask";
import styles from "./details.module.scss";
import { Divider } from "primereact/divider";
import { Detail } from "./detail/detail-column";
import { PersonalAccount } from "../../results/results-context";
import { useCurrentLanguage } from "../../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../../language/supported-languages";
import { InputText } from "primereact/inputtext";

const GERMAN_TRANSLATIONS = {
  accountNumber: "Kontonummer",
  accountNameRequired: "Kontoname ist erforderlich",
  accountName: "Kontoname",
  creditorDebitor: "Kreditor / Debitor",
  creditor: "Kreditor",
  debitor: "Debitor",
  bookings: "Buchungen",
};

const ENGLISH_TRANSLATIONS = {
  accountNumber: "Account Number",
  accountNameRequired: "Account name is required",
  accountName: "Account Name",
  creditorDebitor: "Creditor / Debitor",
  creditor: "Creditor",
  debitor: "Debitor",
  bookings: "Bookings",
};

export function Details(props: {
  selectedAccount: PersonalAccount;
  form: UseFormReturn<EditAccountFormValue>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.rows}>
      <div className={styles.row}>
        <div className={styles.flex_1}>
          <Detail label={translations.accountNumber}>
            <p className={styles.bigText}>
              {props.selectedAccount.account_number}
            </p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={`${styles.flex_3} `}>
          <Detail label={translations.accountName}>
            <Controller
              name="account_name"
              control={props.form.control}
              render={({ field }) => (
                <InputText
                  id="account_name"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  className={`${styles.width100} ${
                    props.form.formState.errors.account_name ? "p-invalid" : ""
                  }`}
                />
              )}
            />
            {props.form.formState.errors.account_name && (
              <small className="p-error">
                {translations.accountNameRequired}
              </small>
            )}
          </Detail>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.flex_1}>
          <Detail label={translations.creditorDebitor}>
            <p className={styles.bigText}>
              {props.selectedAccount.debitor_or_creditor === "debitor"
                ? translations.debitor
                : translations.creditor}
            </p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={styles.flex_3}>
          <Detail label={translations.bookings}>
            <p className={styles.bigText}>
              {props.selectedAccount.total_transactions}
            </p>
          </Detail>
        </div>
      </div>
    </div>
  );
}
