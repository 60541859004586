import { useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import z from "zod";
import { useMainApi } from "../../../../../../main-api";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../communication-errors/communication-error-messages";
import {
  InvitedAccountingClient,
  InvitedAccountingClientSchema,
} from "../loader/invited-accounting-clients-loader";

const GERMAN_TRANSLATIONS = {
  success: "Erfolg",
  error: "Fehler",
  failedToSendEmail:
    "E-Mail-Adresse nicht gefunden. Bitte prüfen Sie die Kontaktdaten des Mandanten.",
  successDetail: "E-Mail erfolgreich an den Mandanten gesendet",
  resendEmail: "E-Mail erneut senden",
};

const ENGLISH_TRANSLATIONS = {
  success: "Success",
  error: "Error",
  failedToSendEmail:
    "Email address not found. Please check client's contact details.",
  successDetail: "Email successfully sent to the client",
  resendEmail: "Resend Email",
};

export function ResendInvitationEmail({
  accountingClient,
  onAccountingClientUpdated,
  toastRef,
  permissionsGranted,
}: {
  accountingClient: InvitedAccountingClient;
  onAccountingClientUpdated: (
    accountingClient: InvitedAccountingClient
  ) => void;
  toastRef: React.RefObject<Toast>;
  permissionsGranted: boolean;
}) {
  const mainApi = useMainApi();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const sendEmail = async () => {
    setIsSubmitting(true);

    const response = await mainApi.fetchJSON({
      path: `/accounting_clients/onboarding/${accountingClient.id}/resend_invite_link`,
      method: "POST",
      schema: z.object({
        status: z.literal(200),
        body: InvitedAccountingClientSchema,
      }),
    });

    setIsSubmitting(false);

    if (response.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(response.error),
        detail: getCommunicationErrorMessage(response.error),
      });
      return;
    }

    toastRef.current?.show({
      severity: "success",
      summary: translations.success,
      detail: translations.successDetail,
    });

    onAccountingClientUpdated(response.response.body);
  };

  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          sendEmail();
        }}
        link
        loading={isSubmitting}
        label={translations.resendEmail}
        disabled={permissionsGranted}
      />
    </>
  );
}
