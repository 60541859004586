import formSections from "./form-sections.module.scss";
import { Controller, UseFormReturn } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { AccountingClientFormValue } from "../basic-information";
import { AccountingClient, FormOptions } from "../../accountingClientLoader";

const GERMAN_TRANSLATIONS = {
  clientNumber: "Mandantennummer *",
  clientInformation: "Mandanteninformationen",
  clientType: "Mandantentyp *",
  company: "Firma",
  name: "Name *",
  startingDate: "Abrechnungsbeginn *",
  systemOfAccounts: "Kontenrahmen *",
  selectSysteemOfAccounts: "Bitte wählen Sie einen Kontenrahmen aus",
  vatId: "Ust Id *",
};

const ENGLISH_TRANSLATIONS = {
  clientNumber: "Client Number *",
  clientInformation: "Client Information",
  clientType: "Client Type *",
  company: "Company",
  name: "Name *",
  startingDate: "Start accounting *",
  systemOfAccounts: "System of Accounts *",
  selectSysteemOfAccounts: "Please select a system of accounts type",
  vatId: "Vat Id *",
};

export function ClientData(props: {
  accountingClient: AccountingClient | null;
  form: UseFormReturn<AccountingClientFormValue>;
  system_of_accounts_types: FormOptions["system_of_accounts_types"];
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <div className={formSections.formField}>
        <label className={formSections.label} htmlFor="client_number">
          {translations.clientNumber}
        </label>
        <div className={formSections.inputWrapper}>
          <Controller
            name="client_number"
            control={props.form.control}
            render={({ field, fieldState }) => (
              <InputText
                className={`${formSections.input} ${
                  fieldState.error ? "p-invalid" : ""
                }`}
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          {props.form.formState.errors.client_number && (
            <small className={formSections.errorMessage}>
              {props.form.formState.errors.client_number.message}
            </small>
          )}
        </div>
      </div>

      <div className={formSections.formField}>
        <label className={formSections.label} htmlFor="client_type">
          {translations.clientType}
        </label>
        <div className={formSections.inputWrapper}>
          <Dropdown
            className={`${formSections.input}`}
            value={"company"}
            disabled
            onChange={() => {}}
            options={[{ label: translations.company, value: "company" }]}
          />
        </div>
      </div>

      <div className={formSections.formField}>
        <label className={formSections.label} htmlFor="name">
          {translations.name}
        </label>
        <div className={formSections.inputWrapper}>
          <Controller
            name="name"
            control={props.form.control}
            render={({ field, fieldState }) => (
              <InputText
                className={`${formSections.input} ${
                  fieldState.error ? "p-invalid" : ""
                }`}
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          {props.form.formState.errors.name && (
            <small className={formSections.errorMessage}>
              {props.form.formState.errors.name.message}
            </small>
          )}
        </div>
      </div>

      <div className={formSections.formField}>
        <label className={formSections.label} htmlFor="vat_id">
          {translations.vatId}
        </label>
        <div className={formSections.inputWrapper}>
          <Controller
            name="vat_id"
            control={props.form.control}
            render={({ field, fieldState }) => (
              <InputText
                className={`${formSections.input} ${
                  fieldState.error ? "p-invalid" : ""
                }`}
                id={field.name}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          {props.form.formState.errors.vat_id && (
            <small className={formSections.errorMessage}>
              {props.form.formState.errors.vat_id.message}
            </small>
          )}
        </div>
      </div>

      <div className={formSections.formField}>
        <Controller
          name="system_of_accounts"
          control={props.form.control}
          render={({ field, fieldState }) => {
            return (
              <>
                <label className={formSections.label} htmlFor={field.name}>
                  {translations.systemOfAccounts}
                </label>
                <div className={`${formSections.inputWrapper}`}>
                  <Dropdown
                    disabled
                    placeholder={translations.selectSysteemOfAccounts}
                    className={`${formSections.input} ${
                      fieldState.error ? "p-invalid" : ""
                    }`}
                    id={field.name}
                    options={[
                      ...props.system_of_accounts_types.map((type) => ({
                        label: `${type.name} (${type.system_of_accounts_number})`,
                        value: type.id,
                      })),
                    ]}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                  />
                  {fieldState.error && (
                    <small className={formSections.errorMessage}>
                      {fieldState.error.message}
                    </small>
                  )}
                </div>
              </>
            );
          }}
        />
      </div>
    </>
  );
}
