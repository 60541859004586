import { Column } from "react-data-grid";
import { Badge } from "primereact/badge";
import { Toast } from "primereact/toast";
import styles from "./invited-accounting-clients-table.module.scss";
import { InvitedAccountingClient } from "../loader/invited-accounting-clients-loader";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { ResendInvitationEmail } from "./resend-invitation";
import { ReactDataGrid } from "../../../../../../react-data-grid";
import { RadioButton } from "primereact/radiobutton";

const ENGLISH_TRANSLATIONS = {
  name: "Name",
  email: "Email",
  invitationMailSent: "Invitation Mail Sent",
  permissionGranted: "Permission Granted",
  status: "Status",
  awaitingConsent: "Awaiting Consent",
  consentGiven: "Consent Given",
  awaitingBankConnection: "Awaiting Bank Connection",
  accountsNeedSetup: "Accounts Need Setup",
  expirationDate: "Expiration Date",
  resendEmail: "Resend Email",
};

const GERMAN_TRANSLATIONS = {
  name: "Name",
  email: "Email",
  invitationMailSent: "Einladungs-Mail gesendet",
  status: "Status",
  awaitingConsent: "Zustimmung ausstehend",
  consentGiven: "Zustimmung erteilt",
  awaitingBankConnection: "Bankverbindung ausstehend",
  accountsNeedSetup: "Konten müssen eingerichtet werden",
  permissionGranted: "Berechtigung erteilt",
  expirationDate: "Ablaufdatum",
  resendEmail: "E-Mail erneut senden",
};

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  dateStyle: "medium",
  timeStyle: "short",
});

export function InvitedAccountingClientsTable(props: {
  accountingClients: InvitedAccountingClient[];
  selectedAccountingClient: InvitedAccountingClient | undefined;
  onAccountingClientSelect: (AccountingClientId: string | undefined) => void;
  onAccountingClientUpdated: (
    accountingClient: InvitedAccountingClient
  ) => void;
  toastRef: React.RefObject<Toast>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const columns: Column<InvitedAccountingClient>[] = [
    {
      key: "selection",
      name: "",
      width: 40,
      renderCell: (args) => {
        return (
          <div className="flex justify-content-center">
            <RadioButton
              checked={props.selectedAccountingClient?.id === args.row.id}
              onChange={() => props.onAccountingClientSelect(args.row.id)}
              inputId={args.row.id}
            />
          </div>
        );
      },
    },
    {
      key: "name",
      name: translations.name,
      resizable: true,
      renderCell: (args) => {
        return args.row.name;
      },
    },
    {
      key: "email",
      name: translations.email,
      resizable: true,
      renderCell: (args) => {
        return args.row.email;
      },
    },
    {
      key: "last_invitation_email_sent_at",
      name: translations.invitationMailSent,
      resizable: true,
      renderCell: (args) => {
        return args.row.last_invitation_email_sent_at
          ? dateFormatter.format(
              new Date(args.row.last_invitation_email_sent_at)
            )
          : "";
      },
    },
    {
      key: "status",
      name: "Status",
      resizable: true,
      renderCell: (args) => {
        return (
          <>
            {(() => {
              if (args.row.status === "AWAITING_CONSENT") {
                return (
                  <Badge
                    value={translations.awaitingConsent}
                    severity="secondary"
                  />
                );
              } else if (args.row.status === "CONSENT_GIVEN") {
                return (
                  <Badge value={translations.consentGiven} severity="success" />
                );
              } else if (
                args.row.status === "AWAITING_BANK_ACCOUNT_CONNECTION"
              ) {
                return (
                  <Badge
                    value={translations.awaitingBankConnection}
                    severity="secondary"
                  />
                );
              } else if (args.row.status === "ACCOUNTS_NEED_SETUP") {
                return (
                  <Badge
                    value={translations.accountsNeedSetup}
                    severity="success"
                  />
                );
              } else {
                throw new Error();
              }
            })()}
          </>
        );
      },
    },
    {
      key: "expiration_date",
      name: translations.expirationDate,
      resizable: true,
      renderCell: (args) => {
        return args.row.expiration_date
          ? dateFormatter.format(new Date(args.row.expiration_date))
          : "";
      },
    },
    {
      key: "resend_email",
      name: translations.resendEmail,
      resizable: true,
      renderCell: (args) => {
        return (
          <ResendInvitationEmail
            accountingClient={args.row}
            onAccountingClientUpdated={props.onAccountingClientUpdated}
            toastRef={props.toastRef}
            permissionsGranted={args.row.permission_granted}
          />
        );
      },
    },
  ];

  return (
    <ReactDataGrid
      rows={props.accountingClients}
      selectedRows={
        props.selectedAccountingClient
          ? new Set<string>([props.selectedAccountingClient.id])
          : new Set<string>()
      }
      columns={columns}
      rowKeyGetter={(r) => r.id}
      rowHeight={60}
      rowClass={(row) =>
        props.selectedAccountingClient?.id === row.id
          ? styles.highlightedRow
          : ""
      }
      onSelectedCellChange={(args) => {
        if (!args.row) {
          return;
        }

        props.onAccountingClientSelect(args.row.id);
      }}
      className={styles.table}
    />
  );
}
