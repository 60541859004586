import { useContext } from "react";
import { throwError } from "../../../../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import styles from "./onboard-client-with-gdpdu-file.module.scss";
import UploadToPreviewGdpduButton from "./upload-to-preview-gdpdu-button";
import { AccountingClientContext } from "../../../accounting-client-context";

const ENGLISH_TRANSLATIONS = {
  clientSetupWithGDPDU: "Client setup with GDPdU upload",
};

const GERMAN_TRANSLATIONS = {
  clientSetupWithGDPDU: "Mandanteneinrichtung mit GDPdU",
};

export function OnboardClientWithGdpduFile({
  onNextStep,
}: {
  onNextStep: () => void;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { accountingClient } =
    useContext(AccountingClientContext) || throwError();

  return (
    <>
      <div className={styles.displayFlex}>
        <UploadToPreviewGdpduButton
          onNextStep={onNextStep}
          accountingClient={accountingClient}
          label={translations.clientSetupWithGDPDU}
        />
      </div>
    </>
  );
}
