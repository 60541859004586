import { ReactNode, createContext, useMemo, useState } from "react";
import { useAccountingClientId } from "../../accounting-client-id";
import { z } from "zod";

export const FiltersSchema = z.object({
  merchant_name: z.string().nullable(),
  amount_min: z.number().nullable(),
  amount_max: z.number().nullable(),
  created_at_min: z.date({ coerce: true }).nullable(),
  created_at_max: z.date({ coerce: true }).nullable(),
  entry_type: z.array(z.enum(["debit", "credit"])),
});

export type Filters = z.infer<typeof FiltersSchema>;

function useContextValue() {
  const accountingClientId = useAccountingClientId();
  const [filters, setFilters] = useState<Filters>({
    merchant_name: null,
    amount_min: null,
    amount_max: null,
    created_at_min: null,
    created_at_max: null,
    entry_type: [],
  });

  return useMemo(() => {
    return {
      accountingClientId,
      filters,
      setFilters,
    };
  }, [accountingClientId, filters, setFilters]);
}

export const BankTransactionsPageFiltersContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);

export function BankTransactionsPageFiltersProvider(props: {
  children: ReactNode;
}) {
  const value = useContextValue();

  return (
    <BankTransactionsPageFiltersContext.Provider value={value}>
      {props.children}
    </BankTransactionsPageFiltersContext.Provider>
  );
}
