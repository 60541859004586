import { ReactNode, createContext, useState } from "react";
import { DEFAULT_SORT_COLUMN } from "./personal-accounts-sorting-constants";

export type SortColumn = {
  columnKey: "account_number" | "account_name" | "debitor_or_creditor";
  direction: "ASC" | "DESC";
};

export type SortingContextType = {
  sortColumn: SortColumn;
  setSortColumn: (column: SortColumn) => void;
};

function useContextValue() {
  const [sortColumn, setSortColumn] = useState<SortColumn>(DEFAULT_SORT_COLUMN);

  return {
    sortColumn,
    setSortColumn,
  };
}

export const PersonalAccountsSortingContext = createContext<
  ReturnType<typeof useContextValue> | undefined
>(undefined);

export function PersonalAccountsSortingContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const value = useContextValue();
  return (
    <PersonalAccountsSortingContext.Provider value={value}>
      {children}
    </PersonalAccountsSortingContext.Provider>
  );
}
