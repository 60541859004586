import { ReactNode } from "react";
import styles from "./page.module.scss";
import { NominalAccountsToolbar } from "./toolbar/nominal-accounts-toolbar";
import { NominalAccounts as _NominalAccounts } from "./results/results";
import { DetailsMask } from "./details-mask/details-mask";
import { NominalAccountsFiltersContextProvider } from "./toolbar/filters-context";
import { NominalAccountsSortingContextProvider } from "./sorting/sorting-context";
import { NominalAccountsResultsContextProvider } from "./results/results-context";
import { AccountingClient } from "../../../accountingClientLoader";

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export default function NominalAccounts({
  accountingClient,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  return (
    <NominalAccountsFiltersContextProvider accountingClient={accountingClient}>
      <NominalAccountsSortingContextProvider>
        <NominalAccountsResultsContextProvider
          selectedAccountId={selectedAccountId}
          setSelectedAccountId={setSelectedAccountId}
        >
          <Page>
            <NominalAccountsToolbar />
            <_NominalAccounts setSelectedAccountId={setSelectedAccountId} />
            <DetailsMask />
          </Page>
        </NominalAccountsResultsContextProvider>
      </NominalAccountsSortingContextProvider>
    </NominalAccountsFiltersContextProvider>
  );
}
