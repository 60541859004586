import { useContext, useCallback, useMemo, useRef } from "react";
import { Column } from "react-data-grid";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import { DEFAULT_SORT_COLUMN } from "../sorting/sorting-constants";
import styles from "./results.module.scss";
import { Checkbox } from "primereact/checkbox";
import { ProgressSpinner } from "primereact/progressspinner";
import { getCommunicationErrorMessage } from "../../../../../../../../../communication-errors/communication-error-messages";
import { throwError } from "../../../../../../../../../throw-error";
import {
  SortColumn,
  NominalAccountsSortingContext,
} from "../sorting/sorting-context";
import {
  NominalAccount,
  NominalAccountsResultsContext,
} from "./results-context";
import { Toast } from "primereact/toast";
import { useCurrentLanguage } from "../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../language/supported-languages";
import { ReactDataGrid } from "../../../../../../../../../react-data-grid";

const GERMAN_TRANSLATIONS = {
  accountNumber: "Kontonummer",
  accountName: "Kontoname",
  bankAccount: "Bankkonto",
  bookings: "Buchungen",
  loadingMoreRows: "Weitere Zeilen werden geladen...",
};

const ENGLISH_TRANSLATIONS = {
  accountNumber: "Account Number",
  accountName: "Account Name",
  bankAccount: "Bank Account",
  bookings: "Bookings",
  loadingMoreRows: "Loading more rows...",
};

export function NominalAccounts({
  setSelectedAccountId,
}: {
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  const { results, setOffset } =
    useContext(NominalAccountsResultsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const sorting = useContext(NominalAccountsSortingContext) || throwError();

  const toastRef = useRef<Toast>(null);

  const getRowKey = useCallback(
    (r: Partial<NominalAccount>) => r.id || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn((sortColumn ?? DEFAULT_SORT_COLUMN) as SortColumn);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return [sorting.sortColumn];
  }, [sorting]);

  const columns: Column<NominalAccount>[] = useMemo(() => {
    return [
      {
        key: "account_number",
        name: `${translations.accountNumber}`,
        sortable: true,
      },
      {
        key: "account_name",
        name: `${translations.accountName}`,
        sortable: true,
      },
      {
        key: "is_connected_to_bank_account",
        name: `${translations.bankAccount}`,
        renderCell: (args) => {
          if (!args.row.is_connected_to_bank_account) {
            return <></>;
          }
          return (
            <div className={styles.rowIconWrapper}>
              <Checkbox
                checked={args.row.is_connected_to_bank_account}
                readOnly={true}
              />
            </div>
          );
        },
        sortable: true,
      },
      {
        key: "total_transactions",
        name: `${translations.bookings}`,
        sortable: true,
      },
    ];
  }, [translations]);

  return (
    <>
      <Toast ref={toastRef} />
      {(() => {
        const data = results.data;

        if (results.loading && !results.data) {
          return (
            <div className={styles.loadingContainer}>
              <ProgressSpinner />
            </div>
          );
        }

        if (results.error) {
          return (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>
                {getCommunicationErrorMessage(results.error)}
              </p>
            </div>
          );
        }

        if (data && data.rows.length > 0) {
          return (
            <>
              <ReactDataGrid
                columns={columns}
                rows={results.data.rows}
                sortColumns={sortColumns}
                rowKeyGetter={getRowKey}
                onSortColumnsChange={onSortColumnsChange}
                onSelectedCellChange={(args) => {
                  if (!args.row) {
                    return;
                  }

                  setSelectedAccountId(args.row.id);
                }}
                onWheelAndBottom={() => {
                  setOffset(data.rows.length);
                }}
                className={`${styles.table}`}
              />

              {results.loading && (
                <div className={styles.loadingNextPageContainer}>
                  {translations.loadingMoreRows}
                </div>
              )}
            </>
          );
        }
      })()}
    </>
  );
}
