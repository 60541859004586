import { ReactNode, createContext, useMemo, useState } from "react";
import { z } from "zod";

export const FiltersSchema = z.object({
  search: z.string().nullable(),
});

export type Filters = z.infer<typeof FiltersSchema>;

function useContextValue() {
  const [filters, setFilters] = useState<Filters>({
    search: null,
  });

  return useMemo(() => {
    return {
      filters,
      setFilters,
    };
  }, [filters, setFilters]);
}

export const NominalAccountsFiltersContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);

export function NominalAccountsFiltersContextProvider(props: {
  children: ReactNode;
}) {
  const value = useContextValue();

  return (
    <NominalAccountsFiltersContext.Provider value={value}>
      {props.children}
    </NominalAccountsFiltersContext.Provider>
  );
}
