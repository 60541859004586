import { ReactNode } from "react";
import styles from "./page.module.scss";
import { PaymentAccountsToolbar } from "./toolbar/payment-accounts-toolbar";
import { PaymentAccounts as _PaymentAccounts } from "./results/results";
import { DetailsMask } from "./details-mask/details-mask";
import { PaymentAccountsFiltersContextProvider } from "./toolbar/filters-context";
import { PaymentAccountsSortingContextProvider } from "./sorting/sorting-context";
import { PaymentAccountsResultsContextProvider } from "./results/results-context";
import { AccountingClient } from "../../../accountingClientLoader";

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export default function PaymentAccounts({
  accountingClient,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  return (
    <PaymentAccountsFiltersContextProvider accountingClient={accountingClient}>
      <PaymentAccountsSortingContextProvider>
        <PaymentAccountsResultsContextProvider
          selectedAccountId={selectedAccountId}
          setSelectedAccountId={setSelectedAccountId}
        >
          <Page>
            <PaymentAccountsToolbar />
            <_PaymentAccounts setSelectedAccountId={setSelectedAccountId} />
            <DetailsMask />
          </Page>
        </PaymentAccountsResultsContextProvider>
      </PaymentAccountsSortingContextProvider>
    </PaymentAccountsFiltersContextProvider>
  );
}
