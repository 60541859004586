import { useContext } from "react";
import styles from "./gdpdu.module.scss";
import { GdpduContext } from "../../gdpdu-preview-context";
import { OnboardClientWithGdpduFile } from "./onboard-client-with-gdpdu-file/onboard-client-with-gdpdu-file";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import OnboardingHeader from "../../onboarding-header";
import { throwError } from "../../../../../../../throw-error";

const GERMAN_TRANSLATIONS = {
  uploadGDPDU: "GDPdU-Datei hochladen",
};
const ENGLISH_TRANSLATIONS = {
  uploadGDPDU: "Upload GDPdU",
};

function Gdpdu(props: { onPrevStep: () => void; onNextStep: () => void }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { gdpduPreviewState } = useContext(GdpduContext) || throwError();

  return (
    <div className={styles.page}>
      <>
        <OnboardingHeader
          titleProps={{
            text: translations.uploadGDPDU,
            className: styles.maxWidth,
          }}
          previousButtonProps={{
            onClick: () => props.onPrevStep(),
          }}
          nextButtonProps={{
            onClick: () => props.onNextStep(),
            disabled: !gdpduPreviewState,
          }}
        />
      </>

      <OnboardClientWithGdpduFile onNextStep={props.onNextStep} />
    </div>
  );
}

export default Gdpdu;
