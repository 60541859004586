import { useRef, useState } from "react";
import { Stepper, StepperRefAttributes } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { Button } from "primereact/button";
import styles from "./create-accounting-clients-steps.module.scss";
import { useNavigate } from "react-router-dom";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { AccountingClientContextProvider } from "./accounting-client-context";
import { InvitedAccountingClientLoader } from "./invited-accounting-client-loader";
import { GdpduPreviewContextProvider } from "./gdpdu-preview-context";
import GDPdU from "./steps/gdpdu/gdpdu";
import BasicInformation from "./steps/basic-information/basic-information";
import AccountsOverview from "./steps/accounts-overview/accounts-overview";
import BankInformation from "./steps/bank-information/bank-information";
import { CREATE_CLIENTS_ROUTES } from "../../create/create-clients-routes";
import { Skip } from "./skip";
import { Finishing } from "./finishing";

const ENGLISH_TRANSLATIONS = {
  initializeAClient: "Initialize a Client",
  uploadGdpduFile: "Upload A GDPdU File",
  confirmClientInfo: "Confirm Client Information",
  connectBankAccount: "Connect A Bank Account",
  reviewAccounts: "Review Created Accounts",
  backToDashboard: "Back to Dashboard",
};

const GERMAN_TRANSLATIONS = {
  initializeAClient: "Mandanten initialisieren",
  uploadGdpduFile: "GDPdU-Datei hochladen",
  confirmClientInfo: "Mandanteninformationen bestätigen",
  connectBankAccount: "Bankkonto verbinden",
  reviewAccounts: "Erstellte Konten überprüfen",
  backToDashboard: "Zurück zum Dashboard",
};

export default function Page() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const stepperRef = useRef<StepperRefAttributes>(null);
  const navigate = useNavigate();

  const [finishing, setFinishing] = useState(false);

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <Button
          icon="pi pi-arrow-left"
          link
          label={translations.backToDashboard}
          onClick={() => navigate(`/accountant`)}
        />
      </div>

      <div className={styles.body}>
        <InvitedAccountingClientLoader>
          {({ accountingClient, setAccountingClient, formOptions }) => {
            return (
              <AccountingClientContextProvider
                accountingClient={accountingClient}
                setAccountingClient={setAccountingClient}
                formOptions={formOptions}
              >
                <GdpduPreviewContextProvider>
                  {(() => {
                    if (!finishing) {
                      return (
                        <Stepper ref={stepperRef} linear={true}>
                          <StepperPanel header={translations.initializeAClient}>
                            <Skip
                              onSkip={() => stepperRef.current?.nextCallback()}
                            />
                          </StepperPanel>
                          <StepperPanel header={translations.uploadGdpduFile}>
                            {accountingClient.has_gdpdu_upload ? (
                              <Skip
                                onSkip={() =>
                                  stepperRef.current?.nextCallback()
                                }
                              />
                            ) : (
                              <GDPdU
                                onPrevStep={() => {
                                  stepperRef.current?.prevCallback();
                                  navigate(CREATE_CLIENTS_ROUTES.getHref());
                                }}
                                onNextStep={() =>
                                  stepperRef.current?.nextCallback()
                                }
                              />
                            )}
                          </StepperPanel>
                          <StepperPanel header={translations.confirmClientInfo}>
                            {accountingClient.has_gdpdu_upload ? (
                              <Skip
                                onSkip={() =>
                                  stepperRef.current?.nextCallback()
                                }
                              />
                            ) : (
                              <BasicInformation
                                onPrevStep={() => {
                                  stepperRef.current?.prevCallback();
                                }}
                                onNextStep={() =>
                                  stepperRef.current?.nextCallback()
                                }
                              />
                            )}
                          </StepperPanel>
                          <StepperPanel
                            header={translations.connectBankAccount}
                          >
                            <BankInformation
                              onPrevStep={() => {
                                navigate(CREATE_CLIENTS_ROUTES.getHref());
                              }}
                              onNextStep={() => {
                                stepperRef.current?.nextCallback();
                              }}
                            />
                          </StepperPanel>
                          <StepperPanel header={translations.reviewAccounts}>
                            <AccountsOverview
                              onPrevStep={() => {
                                stepperRef.current?.prevCallback();
                              }}
                              onNextStep={() => setFinishing(true)}
                            />
                          </StepperPanel>
                        </Stepper>
                      );
                    } else if (finishing) {
                      return <Finishing />;
                    } else {
                      throw new Error();
                    }
                  })()}
                </GdpduPreviewContextProvider>
              </AccountingClientContextProvider>
            );
          }}
        </InvitedAccountingClientLoader>
      </div>
    </div>
  );
}
