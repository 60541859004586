import { Button } from "primereact/button";
import styles from "./create-accounting-clients-page.module.scss";
import { useNavigate } from "react-router-dom";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { Stepper, StepperRefAttributes } from "primereact/stepper";
import { StepperPanel } from "primereact/stepperpanel";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { InvitedAccountingClientsLoader } from "./invited-accounting-clients/loader/invited-accounting-clients-loader";
import { InvitedAccountingClients } from "./invited-accounting-clients";

const ENGLISH_TRANSLATIONS = {
  initializeAClient: "Initialize a Client",
  uploadGdpduFile: "Upload A GDPdU File",
  confirmClientInfo: "Confirm Client Information",
  connectBankAccount: "Connect A Bank Account",
  reviewAccounts: "Review Created Accounts",
  backToDashboard: "Back to Dashboard",
};

const GERMAN_TRANSLATIONS = {
  initializeAClient: "Mandanten initialisieren",
  uploadGdpduFile: "GDPdU-Datei hochladen",
  confirmClientInfo: "Mandanteninformationen bestätigen",
  connectBankAccount: "Bankkonto verbinden",
  reviewAccounts: "Erstellte Konten überprüfen",
  backToDashboard: "Zurück zum Dashboard",
};

export default function CreateAccountingClientsPage() {
  const toast = useRef<Toast | null>(null);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const stepperRef = useRef<StepperRefAttributes>(null);
  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <Button
          icon="pi pi-arrow-left"
          link
          label={translations.backToDashboard}
          onClick={() => navigate("/accountant/")}
        />
      </div>
      <Toast ref={toast} />
      <div className={styles.body}>
        <div className={styles.stepper}>
          <Stepper ref={stepperRef} linear={true}>
            <StepperPanel
              header={translations.initializeAClient}
            ></StepperPanel>
            <StepperPanel header={translations.uploadGdpduFile}></StepperPanel>
            <StepperPanel
              header={translations.confirmClientInfo}
            ></StepperPanel>
            <StepperPanel
              header={translations.connectBankAccount}
            ></StepperPanel>
            <StepperPanel header={translations.reviewAccounts}></StepperPanel>
          </Stepper>
        </div>
        <div className={styles.content}>
          <InvitedAccountingClientsLoader toast={toast}>
            {({
              accountingClients,
              onAccountingClientCreated,
              onAccountingClientUpdated,
            }) => {
              return (
                <InvitedAccountingClients
                  accountingClients={accountingClients}
                  onAccountingClientCreated={onAccountingClientCreated}
                  onAccountingClientUpdated={onAccountingClientUpdated}
                  toast={toast}
                />
              );
            }}
          </InvitedAccountingClientsLoader>
        </div>
      </div>
    </div>
  );
}
