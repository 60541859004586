import { useEffect, useState } from "react";

export function Skip(props: { onSkip: () => void }) {
  const [onSkip] = useState(() => props.onSkip);

  useEffect(() => {
    onSkip();
  }, [onSkip]);

  return <></>;
}
