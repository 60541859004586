import { useState } from "react";
import styles from "./gdpdu.module.scss";
import UploadGDPDUButton from "./upload-gdpdu-button";
import GdpduUploads from "./gdpdu-uploads/gdpdu-uploads";
import { AccountingClient } from "../accountingClientLoader";
import { HEADINGS_CLASS_NAMES } from "../../../../../../ui/headings";

function Gdpdu(props: {
  accountingClient: AccountingClient;
  setAccountingClient: (accountingClient: AccountingClient) => void;
}) {
  const [forcedUpdateKey, setForcedUpdateKey] = useState(0);

  return (
    <div className={styles.page}>
      <h2 className={HEADINGS_CLASS_NAMES.h2}>GDPdU</h2>
      <UploadGDPDUButton
        accountingClient={props.accountingClient}
        setAccountingClient={props.setAccountingClient}
        setForcedUpdateKey={setForcedUpdateKey}
      />

      <GdpduUploads forcedUpdateKey={forcedUpdateKey} />
    </div>
  );
}

export default Gdpdu;
