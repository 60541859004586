import { z } from "zod";
import { useAccountingClientId } from "../../../../../../accounting-client-id";
import { useMainApi } from "../../../../../../../../../../main-api";
import {
  ReactNode,
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
} from "react";
import { CommunicationError } from "../../../../../../../../../../communication-errors/communication-errors";
import {
  PaymentAccountsFiltersContext,
  Filters,
} from "../toolbar/payment-accounts-filters-context";
import {
  PaymentAccountsSortingContext,
  SortColumn,
} from "../sorting/payment-accounts-sorting-context";
import { throwError } from "../../../../../../../../../../throw-error";

export const PaymentAccountSchema = z.object({
  id: z.string().uuid(),
  account_id: z.string().uuid(),
  account_number: z.number(),
  account_name: z.string(),
  iban: z.string().nullable(),
  is_connected_to_bank_account: z.boolean(),
});

export type PaymentAccount = z.TypeOf<typeof PaymentAccountSchema>;

type RequestedParams = {
  offset: number;
  filters: Filters;
  sortColumn: SortColumn;
  accountingClientId: string;
};

function useContextValue() {
  const mainApi = useMainApi();

  const accountingClientId = useAccountingClientId();

  const { sortColumn } =
    useContext(PaymentAccountsSortingContext) || throwError();

  const { filters } = useContext(PaymentAccountsFiltersContext) || throwError();

  const [offset, setOffset] = useState(0);

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        total: number;
        rows: PaymentAccount[];
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const [lastRequestParams, setLastRequestParams] = useState<
    RequestedParams | undefined
  >();

  const [selectedAccountId, setSelectedAccountId] = useState<
    string | undefined
  >();

  useEffect(() => {
    (async () => {
      if (results.loading) {
        return;
      }

      const requestParams: RequestedParams = {
        offset,
        accountingClientId,
        sortColumn,
        filters,
      };

      if (JSON.stringify(requestParams) === JSON.stringify(lastRequestParams)) {
        return;
      }

      setLastRequestParams(requestParams);

      const resetResults =
        JSON.stringify({
          accountingClientId: requestParams.accountingClientId,
          sortColumn: requestParams.sortColumn,
          filters: requestParams.filters,
        }) !==
        JSON.stringify({
          accountingClientId: lastRequestParams?.accountingClientId,
          sortColumn: lastRequestParams?.sortColumn,
          filters: lastRequestParams?.filters,
        });

      if (resetResults) {
        setResults(() => {
          return {};
        });
      }

      const _offset = resetResults ? 0 : offset;

      setResults((results) => {
        return {
          ...results,
          loading: true,
          error: undefined,
        };
      });

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append("sort_by", sortColumn.columnKey);
      urlSearchParams.append("sort_order", sortColumn.direction);
      urlSearchParams.append("offset", _offset.toString());

      if (filters.search) {
        urlSearchParams.append("search", filters.search);
      }

      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_clients/${accountingClientId}/accounts_overview/payment_accounts?${urlSearchParams.toString()}`,
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            total: z.number(),
            accounts: z.array(PaymentAccountSchema),
          }),
        }),
      });

      if (res.error) {
        setResults((results) => {
          return {
            ...results,
            loading: false,
            error: res.error,
          };
        });

        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: false,
          data: {
            total: res.response.body.total,
            rows: resetResults
              ? res.response.body.accounts
              : [...(results.data?.rows || []), ...res.response.body.accounts],
          },
        };
      });
    })();
  }, [
    mainApi,
    accountingClientId,
    offset,
    results,
    sortColumn,
    filters,
    lastRequestParams,
  ]);

  const selectedAccount = results.data?.rows.find(
    (account) => account.id === selectedAccountId
  );

  return useMemo(() => {
    return {
      offset,
      setOffset,
      results,
      setResults,
      accountingClientId,
      selectedAccount,
      selectedAccountId,
      setSelectedAccountId,
    };
  }, [
    offset,
    results,
    accountingClientId,
    selectedAccount,
    selectedAccountId,
    setSelectedAccountId,
  ]);
}

export const PaymentAccountsResultsContext = createContext<
  undefined | ReturnType<typeof useContextValue>
>(undefined);
export function PaymentAccountsResultsContextProvider(props: {
  children: ReactNode;
}) {
  const value = useContextValue();
  return (
    <PaymentAccountsResultsContext.Provider value={value}>
      {props.children}
    </PaymentAccountsResultsContext.Provider>
  );
}
