import formSections from "./form-sections.module.scss";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { HEADINGS_CLASS_NAMES } from "../../../../../../../../ui/headings";

const GERMAN_TRANSLATIONS = {
  contactInformation: "Kontaktinformationen",
  email: "E-Mail",
  phoneNumber: "Telefonnummer",
  preferredContactMethod: "Bevorzugte Kontaktmethode",
  emailOption: "E-Mail",
  phoneOption: "Telefon",
};

const ENGLISH_TRANSLATIONS = {
  contactInformation: "Contact information",
  email: "Email",
  phoneNumber: "Phone number",
  preferredContactMethod: "Preferred contact method",
  emailOption: "Email",
  phoneOption: "Phone",
};

export function ContactInformation(props: {
  data: {
    email: string;
  };
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <h3 className={`${formSections.title} ${HEADINGS_CLASS_NAMES.h3}`}>
        {translations.contactInformation}
      </h3>

      <div className={formSections.formField}>
        <>
          <label className={formSections.label} htmlFor={"email"}>
            {translations.email}
          </label>
          <div className={`${formSections.inputWrapper}`}>
            <p id={"email"} className={`${formSections.input}`}>
              {props.data.email}
            </p>
          </div>
        </>
      </div>
    </>
  );
}
