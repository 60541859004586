import { useState } from "react";
import { useMainApi } from "../../../../../../../../main-api";
import z from "zod";
import {
  AccountingClientBankAccountsSchema,
  AccountingClientBankAccounts,
} from "../bank-information";
import { Toast } from "primereact/toast";
import { BankAccountToBeConnected } from "./bank-account-to-be-connected/bank-account-to-be-connected";
import { Button } from "primereact/button";
import styles from "./connect-bank-accounts-to-nominal-accounts.module.scss";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { useAccountingClientId } from "../../../../accounting-client-id";

const GERMAN_TRANSLATIONS = {
  errorSummary: "Fehler",
  errorDetail: "Fehler beim Verbinden der Bankkonten mit den Sachkonten",
  successSummary: "Erfolg",
  successDetail: "Bankkonten mit Sachkonten verbunden",
  saveButton: "Speichern",
};

const ENGLISH_TRANSLATIONS = {
  errorSummary: "Error",
  errorDetail: "Failed to connect bank accounts to nominal accounts",
  successSummary: "Success",
  successDetail: "Bank accounts connected to nominal accounts",
  saveButton: "Save",
};

export function ConnectBankAccountsToNominalAccounts({
  results,
  setResults,
  toastRef,
}: {
  results: AccountingClientBankAccounts;
  setResults: (results: AccountingClientBankAccounts) => void;
  toastRef: React.RefObject<Toast>;
}) {
  const mainApi = useMainApi();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const accountingClientId = useAccountingClientId();

  const [
    bankAccountIdsToNominalAccountIds,
    setBankAccountIdsToNominalAccountIds,
  ] = useState<Record<string, string | undefined>>({});

  const selectedNominalAccountIds = Object.values(
    bankAccountIdsToNominalAccountIds
  ).filter((value) => !!value);

  return (
    <>
      <div className={`${styles.grid} ${styles.marginBottomSpacer}`}>
        {results.bank_accounts_without_nominal_accounts?.map((bankAccount) => (
          <BankAccountToBeConnected
            selectedNominalAccountId={
              bankAccountIdsToNominalAccountIds[bankAccount.id]
            }
            key={bankAccount.id}
            bankAccount={bankAccount}
            nominalAccountsToConnect={results.nominal_accounts_to_connect.filter(
              (nominalAccount) =>
                !selectedNominalAccountIds.includes(nominalAccount.id) ||
                bankAccountIdsToNominalAccountIds[bankAccount.id] ===
                  nominalAccount.id
            )}
            onChange={(
              bankAccountId: string,
              nominalAccountId: string | undefined
            ) => {
              setBankAccountIdsToNominalAccountIds((prev) => ({
                ...prev,
                [bankAccountId]: nominalAccountId,
              }));
            }}
          />
        ))}
      </div>
      <div className={`${styles.marginBottom1}`}>
        <Button
          type="submit"
          label={translations.saveButton}
          className={styles.submit}
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={async () => {
            setIsSubmitting(true);

            const requestBody = {
              links: Object.entries(bankAccountIdsToNominalAccountIds)
                .filter(
                  ([bankAccountId, nominalAccountId]) =>
                    bankAccountId && nominalAccountId
                )
                .map(([bankAccountId, nominalAccountId]) => ({
                  bank_account_id: bankAccountId,
                  nominal_account_id: nominalAccountId,
                })),
            };

            const res = await mainApi.fetchJSON({
              path: `/accounting_clients/${accountingClientId}/bank_accounts/connect_bank_accounts_to_nominal_accounts`,
              method: "POST",
              body: requestBody,
              schema: z.object({
                status: z.literal(200),
                body: AccountingClientBankAccountsSchema,
              }),
            });

            setIsSubmitting(false);

            if (res.error) {
              toastRef.current?.show({
                severity: "error",
                summary: translations.errorSummary,
                detail: translations.errorDetail,
              });
              return;
            }
            toastRef.current?.show({
              severity: "success",
              summary: translations.successSummary,
              detail: translations.successDetail,
            });

            setResults(res.response.body);
            setBankAccountIdsToNominalAccountIds({});
          }}
        />
      </div>
    </>
  );
}
