import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import styles from "./clientsPage.module.scss";
import { useCurrentLanguage } from "../../../../language/current-language";
import { SupportedLanguage } from "../../../../language/supported-languages";
import { HEADINGS_CLASS_NAMES } from "../../../../ui/headings";

const FiltersSchema = z.object({
  name: z.string().nullable(),
  client_number: z.string().nullable(),
});

const GERMAN_TRANSLATIONS = {
  filters: "Filter",
  name: "Name",
  clientNumber: "Mandantennummer",
  search: "Suchen",
};

const ENGLISH_TRANSLATIONS = {
  filters: "Filters",
  name: "Name",
  clientNumber: "Client Number",
  search: "Search",
};

type FiltersProps = {
  onSearch: (filters: z.TypeOf<typeof FiltersSchema>) => void;
};

export function Filters({ onSearch }: FiltersProps) {
  const form = useForm({
    resolver: zodResolver(FiltersSchema),
    mode: "onChange",
    defaultValues: {
      name: null,
      client_number: null,
    },
  });

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <form
      onSubmit={form.handleSubmit((values) => {
        onSearch(values);
      })}
      className={styles.filtersForm}
    >
      <h3 className={`${styles.filterPanelTitle} ${HEADINGS_CLASS_NAMES.h3}`}>
        {translations.filters}
      </h3>
      <div className={styles.filter}>
        <h4 className={`${styles.filterLabel} ${HEADINGS_CLASS_NAMES.h4}`}>
          {translations.name}
        </h4>
        <Controller
          name="name"
          control={form.control}
          render={({ field }) => (
            <InputText
              id="name"
              value={field.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              className={styles.width100}
            />
          )}
        />
      </div>
      <div className={styles.filter}>
        <h4 className={`${styles.filterLabel} ${HEADINGS_CLASS_NAMES.h4}`}>
          {translations.clientNumber}
        </h4>
        <Controller
          name="client_number"
          control={form.control}
          render={({ field }) => (
            <InputText
              id="client_number"
              value={field.value || ""}
              onChange={(e) => field.onChange(e.target.value)}
              className={styles.width100}
            />
          )}
        />
      </div>
      <Button
        type="submit"
        className={styles.submitButton}
        icon="pi pi-search"
        label={translations.search}
      />
    </form>
  );
}
