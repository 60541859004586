import { Button } from "primereact/button";
import { Accountant, AccountantSchema } from "./accountants-loader";
import styles from "./details-panel.module.scss";
import { SupportedLanguage } from "../../../language/supported-languages";
import { useCurrentLanguage } from "../../../language/current-language";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useId, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { MainApiFetchJSONResponse, useMainApi } from "../../../main-api";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../communication-errors/communication-error-messages";
import { Checkbox } from "primereact/checkbox";
import { HEADINGS_CLASS_NAMES } from "../../../ui/headings";

const ENGLISH_TRANSLATIONS = {
  name: "Name",
  email: "Email",
  save: "Save",
  saved: "Saved",
  cancel: "Cancel",
  required: "Required",
  enterEmailInValidFormat:
    "Enter a valid email address. Make sure to use this format: example@xyz.de.",
  permissions: "Permissions",
  canAdministerAccountingFirm: "Can administer Kanzlei",
  canCreateAccountingClients: "Can create accounting clients",
  duplicateEmailAddress: "Duplicate email address",
  duplicateEmailAddressDetail:
    "An accountant with this email address already exists",
};

const GERMAN_TRANSLATIONS = {
  name: "Name",
  email: "Email",
  save: "Speichern",
  saved: "Gespeichert",
  cancel: "Abbrechen",
  required: "Pflichtfeld",
  enterEmailInValidFormat:
    "Geben Sie eine gültige E-Mail-Adresse ein. Stellen Sie sicher, dass dieses Format verwendet wird: beispiel@xyz.de.",
  permissions: "Berechtigungen",
  canAdministerAccountingFirm: "Kann die Kanzlei verwalten",
  canCreateAccountingClients: "Kann Mandanten erstellen",
  duplicateEmailAddress: "Doppelte E-Mail-Adresse",
  duplicateEmailAddressDetail:
    "Ein Buchhalter mit dieser E-Mail-Adresse existiert bereits",
};

export function DetailsPanel({
  selectedAccountant,
  onCancel,
  onAccountantCreated,
  onAccountantUpdated,
}: {
  selectedAccountant: Accountant | undefined;
  onAccountantCreated: (accountant: Accountant) => void;
  onAccountantUpdated: (accountant: Accountant) => void;
  onCancel: () => void;
}) {
  const toast = useRef<Toast | null>(null);
  const mainApi = useMainApi();

  const idPrefix = useId();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const form = useForm({
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, translations.required),
        email: z.string().email(translations.enterEmailInValidFormat),
        permissions: z.object({
          accounting_firm_admin: z.boolean(),
          can_add_clients: z.boolean(),
        }),
      })
    ),
    defaultValues: selectedAccountant,
  });

  useEffect(() => {
    if (selectedAccountant) {
      form.reset(selectedAccountant);
    } else {
      form.reset({
        name: "",
        email: "",
        permissions: {
          accounting_firm_admin: false,
          can_add_clients: true,
        },
      });
    }
  }, [form, selectedAccountant]);

  return (
    <>
      <Toast ref={toast} />
      <form
        onSubmit={form.handleSubmit(async (data) => {
          let res: MainApiFetchJSONResponse<
            | {
                status: 200;
                body: Accountant;
              }
            | { status: 409; body: { detail: "email-is-taken" } }
          >;

          if (selectedAccountant) {
            res = await mainApi.fetchJSON({
              method: "PUT",
              path: `/accounting_firms/current/accountants/${selectedAccountant.id}`,
              body: data,
              schema: z.union([
                z.object({
                  status: z.literal(409),
                  body: z.object({
                    detail: z.literal("email-is-taken"),
                  }),
                }),
                z.object({
                  status: z.literal(200),
                  body: AccountantSchema,
                }),
              ]),
            });
          } else {
            res = await mainApi.fetchJSON({
              method: "POST",
              path: "/accounting_firms/current/accountants",
              body: data,
              schema: z.union([
                z.object({
                  status: z.literal(409),
                  body: z.object({
                    detail: z.literal("email-is-taken"),
                  }),
                }),
                z.object({
                  status: z.literal(200),
                  body: AccountantSchema,
                }),
              ]),
            });
          }

          if (res.error) {
            toast.current!.show({
              severity: "error",
              summary: getCommunicationErrorTitle(res.error),
              detail: getCommunicationErrorMessage(res.error),
            });

            return;
          }

          if (res.response.status == 409) {
            if (res.response.body.detail === "email-is-taken") {
              toast.current!.show({
                severity: "error",
                summary: translations.duplicateEmailAddress,
                detail: translations.duplicateEmailAddressDetail,
              });

              return;
            } else {
              throw new Error();
            }
          }

          toast.current!.show({
            severity: "success",
            summary: translations.saved,
          });

          if (selectedAccountant) {
            onAccountantUpdated(res.response.body);
          } else {
            onAccountantCreated(res.response.body);
          }
        })}
      >
        <div className={styles.header}>
          <div>
            <h3 className={`${HEADINGS_CLASS_NAMES.h3}`}>
              {form.watch("name")}
            </h3>
          </div>
          <div className={styles.headerLeft}>
            <Button
              disabled={form.formState.isSubmitting}
              loading={form.formState.isSubmitting}
              label={translations.save}
              severity={"success"}
              type="submit"
            />
            <Button
              label={translations.cancel}
              severity={"danger"}
              onClick={() => onCancel()}
              type="button"
            />
          </div>
        </div>
        <div className={`${styles.row} ${styles.marginBottom}`}>
          <div
            className={`${styles.formField} ${styles.alignItemsFirstBaseline}`}
          >
            <label className={styles.label} htmlFor={`${idPrefix}-name`}>
              {translations.name}:
            </label>
            <div className={styles.inputField}>
              <Controller
                name="name"
                control={form.control}
                render={({ field, fieldState }) => (
                  <InputText
                    id={`${idPrefix}-name`}
                    className={fieldState.error ? "p-invalid" : ""}
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />

              {form.formState.errors.name && (
                <small className="p-error">
                  {form.formState.errors.name.message}
                </small>
              )}
            </div>
          </div>

          <div
            className={`${styles.formField} ${styles.alignItemsFirstBaseline}`}
          >
            <label className={styles.label} htmlFor={`${idPrefix}-email`}>
              {translations.email}:
            </label>
            <div className={styles.inputField}>
              <Controller
                name="email"
                control={form.control}
                render={({ field, fieldState }) => (
                  <InputText
                    id={`${idPrefix}-email`}
                    className={fieldState.error ? "p-invalid" : ""}
                    value={field.value || ""}
                    onChange={field.onChange}
                  />
                )}
              />

              {form.formState.errors.email && (
                <small className="p-error">
                  {form.formState.errors.email.message}
                </small>
              )}
            </div>
          </div>
        </div>
        <div className={`${styles.divider} ${styles.marginBottom}`}></div>
        <h4 className={`${styles.marginBottom} ${HEADINGS_CLASS_NAMES.h4}`}>
          {translations.permissions}
        </h4>
        <div className={`${styles.row} ${styles.marginBottom}`}>
          <div className={`${styles.formField} ${styles.alignItemsCenter}`}>
            <label
              className={styles.label}
              htmlFor={`${idPrefix}-accounting-firm-admin`}
            >
              {translations.canAdministerAccountingFirm}
            </label>
            <Controller
              name="permissions.accounting_firm_admin"
              control={form.control}
              render={({ field }) => (
                <Checkbox
                  inputId={`${idPrefix}-accounting-firm-admin`}
                  onChange={(e) => field.onChange(e.checked)}
                  checked={field.value}
                />
              )}
            />
          </div>
          <div className={`${styles.formField} ${styles.alignItemsCenter}`}>
            <label
              className={styles.label}
              htmlFor={`${idPrefix}-can-add-clients`}
            >
              {translations.canCreateAccountingClients}
            </label>
            <Controller
              name="permissions.can_add_clients"
              control={form.control}
              render={({ field }) => (
                <Checkbox
                  inputId={`${idPrefix}-can-add-clients`}
                  onChange={(e) => field.onChange(e.checked)}
                  checked={field.value}
                />
              )}
            />
          </div>
        </div>
      </form>
    </>
  );
}
