import { z } from "zod";
import { useContext, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Actions } from "./actions/actions";
import { Details } from "./details/details";
import styles from "./details-mask.module.scss";
import { Panel } from "primereact/panel";
import {
  NominalAccount,
  NominalAccountSchema,
  NominalAccountsResultsContext,
} from "../results/results-context";
import { throwError } from "../../../../../../../../../throw-error";
import { useMainApi } from "../../../../../../../../../main-api";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../../../../communication-errors/communication-error-messages";
import { useCurrentLanguage } from "../../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../../language/supported-languages";
import { useAccountingClientId } from "../../../../../accounting-client-id";

const GERMAN_TRANSLATIONS = {
  confirmationMessage:
    "Sind Sie sicher, dass Sie diese Änderungen speichern möchten?",
  yes: "Ja",
  no: "Nein",
  confirmationHeader: "Bestätigung",
  successMessage: "Kontoname erfolgreich aktualisiert",
  rejected: "Abgelehnt",
  rejectMessage: "Änderungen wurden nicht gespeichert",
  error: "Fehler",
  success: "Erfolg",
  accountNameRequired: "Kontoname ist erforderlich",
};

const ENGLISH_TRANSLATIONS = {
  confirmationMessage: "Are you sure you want to save these changes?",
  yes: "Yes",
  no: "No",
  confirmationHeader: "Confirmation",
  successMessage: "Account name successfully updated",
  rejected: "Rejected",
  rejectMessage: "Changes were not saved",
  error: "Error",
  success: "Success",
  accountNameRequired: "Account name is required",
};

const EditAccountFormSchema = () => {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;
  return z.object({
    account_name: z.string().min(1, translations.accountNameRequired),
  });
};

export type EditAccountFormValue = z.infer<
  ReturnType<typeof EditAccountFormSchema>
>;

function _DetailsMask({
  selectedAccount,
}: {
  selectedAccount: NominalAccount;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const accountingClientId = useAccountingClientId();

  const { setResults } =
    useContext(NominalAccountsResultsContext) || throwError();

  const mainApi = useMainApi();

  const toastRef = useRef<Toast>(null);

  const form = useForm<EditAccountFormValue>({
    resolver: zodResolver(EditAccountFormSchema()),
    defaultValues: {
      account_name: selectedAccount.account_name,
    },
  });

  useEffect(() => {
    form.reset({
      account_name: selectedAccount.account_name,
    });
  }, [form, selectedAccount]);

  const onSubmit = async (formData: EditAccountFormValue) => {
    const res = await mainApi.fetchJSON({
      method: "PUT",
      path: `/accounting_clients/${accountingClientId}/accounts_overview/nominal_accounts/${selectedAccount.account_id}`,
      body: {
        ...formData,
        account_name: formData.account_name,
      },
      schema: z.object({
        status: z.literal(200),
        body: NominalAccountSchema,
      }),
    });

    if (res.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(res.error) ?? translations.error,
        detail: getCommunicationErrorMessage(res.error),
      });

      return;
    }

    setResults((prev) => ({
      ...prev,
      data: prev.data
        ? {
            ...prev.data,
            rows: prev.data.rows.map((account) =>
              account.id === selectedAccount.id
                ? { ...account, account_name: formData.account_name }
                : account
            ),
          }
        : undefined,
    }));

    toastRef.current?.show({
      severity: "success",
      summary: translations.success,
      detail: translations.successMessage,
    });
  };

  return (
    <>
      <Toast ref={toastRef} />
      <ConfirmDialog />
      <form
        onSubmit={form.handleSubmit((formData) => {
          confirmDialog({
            message: translations.confirmationMessage,
            header: translations.confirmationHeader,
            icon: "pi pi-exclamation-triangle",
            acceptLabel: translations.yes,
            rejectLabel: translations.no,
            accept: async () => {
              await onSubmit(formData);
            },
            reject: () => {
              form.reset({
                account_name: selectedAccount.account_name,
              });
              toastRef.current?.show({
                severity: "warn",
                summary: translations.rejected,
                detail: translations.rejectMessage,
              });
            },
          });
        })}
      >
        <Panel
          headerTemplate={() => (
            <Actions
              selectedAccount={selectedAccount}
              isSubmitting={form.formState.isSubmitting}
            />
          )}
        >
          <div className={styles.column}>
            <Details selectedAccount={selectedAccount} form={form} />
          </div>
        </Panel>
      </form>
    </>
  );
}

export function DetailsMask() {
  const { selectedAccount } =
    useContext(NominalAccountsResultsContext) || throwError();

  if (!selectedAccount) {
    return;
  }

  return <_DetailsMask selectedAccount={selectedAccount} />;
}
