import { Card } from "primereact/card";
import styles from "./help-support.module.scss";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { HOW_TO_EXPORT_TO_EDREWE_BOOKINGS_ROUTES } from "./export-bookings-to-edrewe/export-bookings-to-edrewe.route";
import { Link } from "react-router-dom";

const GERMAN_TRANSLATIONS = {
  helpSupport: "Hilfe & Support",
  assistanceText:
    "Benötigen Sie Unterstützung? Wir helfen Ihnen gern. Sie können:",
  contactSupport: "Support kontaktieren",
  reportBugs: "Fehler melden",
  askQuestions: "Fragen stellen",
  suggestFeatures: "Funktionen vorschlagen",
  sendEmailText: "Senden Sie uns eine E-Mail an",
  howTo: "Tutorials",
  exportBookingsToEdrewe:
    "Export von Buchungen aus Kanzlei21 und Import in Edrewe",
};

const ENGLISH_TRANSLATIONS = {
  helpSupport: "Help & Support",
  assistanceText: "Need assistance? We're here to help! You can:",
  contactSupport: "Contact Support",
  reportBugs: "Report Bugs",
  askQuestions: "Ask Questions",
  suggestFeatures: "Suggest Features",
  sendEmailText: "Send us an email at",
  howTo: "How to",
  exportBookingsToEdrewe:
    "Exporting bookings from Kanzlei21 and reimporting them into Edrewe",
};

export default function HelpSupportPage() {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={`${styles.container} ${styles.dFlexColumn}`}>
      <Card title={translations.helpSupport}>
        <div className={styles.dFlexColumn}>
          <p>{translations.assistanceText}</p>
          <ul className="list-disc list-inside">
            <li>{translations.reportBugs}</li>
            <li>{translations.askQuestions}</li>
            <li>{translations.suggestFeatures}</li>
          </ul>
          <p className={styles.bigText}>
            {translations.sendEmailText}{" "}
            <a href="mailto:support@kanzlei21.com">support@kanzlei21.com</a>
          </p>
        </div>
      </Card>
      <Card title={translations.howTo}>
        <div>
          <ul className="list-disc list-inside">
            <li>
              <Link
                to={HOW_TO_EXPORT_TO_EDREWE_BOOKINGS_ROUTES.getHref()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <b>{translations.exportBookingsToEdrewe}</b>
              </Link>
            </li>
          </ul>
        </div>
      </Card>
    </div>
  );
}
