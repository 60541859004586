import styles from "./edit-general-information.module.scss";
import { useId } from "react";
import { Document } from "../../../results/results-context";
import { Controller, UseFormReturn } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { EditDocument } from "../edit-document-data-schemas";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  invoiceNumber: "Rechnungsnummer",
  documentType: "Dokumententyp",
  clientName: "Mandantenname",
  destinationClientName: "Zielmandantenname",
  clientSourceAddress: "Mandantenquelladresse",
  clientDestinationAddress: "Mandantenzieladresse",
  date: "Datum",
  dueDate: "Fälligkeitsdatum",
  paymentTerms: "Zahlungsbedingungen",
};

const ENGLISH_TRANSLATIONS = {
  invoiceNumber: "Invoice Number",
  documentType: "Document Type",
  clientName: "Client Name",
  destinationClientName: "Destination Client Name",
  clientSourceAddress: "Client Source Address",
  clientDestinationAddress: "Client Destination Address",
  date: "Date",
  dueDate: "Due Date",
  paymentTerms: "Payment Terms",
};

export function EditGeneralInformation(props: {
  selectedDocument: Document;
  form: UseFormReturn<EditDocument>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const idPrefix = useId();

  return (
    <div className={styles.formField}>
      <div className={styles.row}>
        <div className={styles.width100}>
          <label
            htmlFor={`${idPrefix}-invoice-number`}
            className={styles.label}
          >
            <b>{translations.invoiceNumber}</b>
          </label>
          <Controller
            name="changed.general_information.invoice_number"
            control={props.form.control}
            render={({ field }) => (
              <InputText
                id={`${idPrefix}-invoice-number`}
                className={styles.input}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>
        <div className={styles.width100}>
          <label htmlFor={`${idPrefix}-document-type`} className={styles.label}>
            <b>{translations.documentType}</b>
          </label>
          <Controller
            name="changed.document_type"
            control={props.form.control}
            render={({ field }) => (
              <Dropdown
                id={`${idPrefix}-document-type`}
                options={[
                  { label: "Invoice", value: "Invoice" },
                  { label: "Receipt", value: "Receipt" },
                  { label: "Unknown", value: "Unknown" },
                ]}
                placeholder="Select document type"
                className={styles.width100}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.width100}>
          <label
            htmlFor={`${idPrefix}-client-source-name`}
            className={styles.label}
          >
            <b>{translations.clientName}</b>
          </label>
          <Controller
            name="changed.general_information.client_source_name"
            control={props.form.control}
            render={({ field }) => (
              <InputText
                id={`${idPrefix}-client-source-name`}
                className={styles.input}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>
        <div className={styles.width100}>
          <label
            htmlFor={`${idPrefix}-client-destination-name`}
            className={styles.label}
          >
            <b>{translations.destinationClientName}</b>
          </label>
          <Controller
            name="changed.general_information.client_destination_name"
            control={props.form.control}
            render={({ field }) => (
              <InputText
                id={`${idPrefix}-client-destination-name`}
                className={styles.input}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.width100}>
          <label
            id={`${idPrefix}-client-source-address`}
            className={styles.label}
          >
            <b>{translations.clientSourceAddress}</b>
          </label>
          <Controller
            name="changed.general_information.client_source_address"
            control={props.form.control}
            render={({ field }) => (
              <InputTextarea
                id={`${idPrefix}-client-source-address`}
                className={styles.input}
                rows={3}
                value={field.value || ""}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>
        <div className={styles.width100}>
          <label
            htmlFor={`${idPrefix}-client-destination-address`}
            className={styles.label}
          >
            <b>{translations.clientDestinationAddress}</b>
          </label>
          <Controller
            name="changed.general_information.client_destination_address"
            control={props.form.control}
            render={({ field }) => (
              <InputTextarea
                id={`${idPrefix}-client-destination-address`}
                className={styles.input}
                rows={3}
                value={field.value || ""}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.width100}>
          <label htmlFor={`${idPrefix}-date`} className={styles.label}>
            <b>{translations.date}</b>
          </label>
          <Controller
            name="changed.general_information.date"
            control={props.form.control}
            render={({ field }) => (
              <Calendar
                id={`${idPrefix}-date`}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                dateFormat="dd MM yy"
                showIcon
                showButtonBar
                className={styles.width100}
              />
            )}
          />
        </div>
        <div className={styles.width100}>
          <label htmlFor={`${idPrefix}-due-date`} className={styles.label}>
            <b>{translations.dueDate}</b>
          </label>
          <Controller
            name="changed.general_information.due_date"
            control={props.form.control}
            render={({ field }) => (
              <Calendar
                id={`${idPrefix}-due-date`}
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                dateFormat="dd MM yy"
                showIcon
                showButtonBar
                className={styles.width100}
              />
            )}
          />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.width100}>
          <label htmlFor={`${idPrefix}-payment-terms`} className={styles.label}>
            <b>{translations.paymentTerms}</b>
          </label>
          <Controller
            name="changed.general_information.payment_terms"
            control={props.form.control}
            render={({ field }) => (
              <InputTextarea
                id={`${idPrefix}-payment-terms`}
                className={styles.input}
                rows={3}
                value={field.value || ""}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
